import store from "../index";
import auth from "../../services/authService";

export var ALL_PRODUCTS = "ALL_PRODUCTS";

function all_products() {
  setTimeout(async () => {
    const products = await auth.getlist();
    store.dispatch({ type: ALL_PRODUCTS, payload: products.data });
  }, 1000);
}

export default all_products;
