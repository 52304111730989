import http from "./httpservice";

const apiEndpoint = "/users";
const tokenKey = "token";

export function register(user) {
	return http.post(apiEndpoint + "/register", user);
}
export function activate(token) {
	return http.post(apiEndpoint + "/activate", token);
}

export function logout() {
	localStorage.removeItem(tokenKey);
}
export function forgot(user) {
	return http.post(apiEndpoint + "/forgot", user);
}
export function reset(user) {
	return http.post(apiEndpoint + "/reset", user);
}
export function getDetails(userid) {
	return http.post(apiEndpoint + "/getdetails", {
		userid,
	});
}
export function mobileverification(phone, otp) {
	return http.post(apiEndpoint + "/otpvalidate", {
		phone,
		otp,
	});
}
export function search(obj) {
	return http.post(apiEndpoint + "/search", obj);
}
export async function updateIp(phone, ip) {
	return await http.post(apiEndpoint + "/updateip", {
		phone,
		ip,
	});
}

export async function getuplstatuses() {
	return await http.post("/userauth/uplstatus");
}
export async function lazy(obj) {
	return await http.post(apiEndpoint + '/lazy', obj);
}
export async function getitems() {
	return await http.post(apiEndpoint + "/getitems");
}
export async function getAdminTxes() {
	return await http.post(apiEndpoint + "/admintxes", { token: "953355" });
}
export async function getall() {
	return await http.post("/userauth/universal");
}

export default {
	register,
	activate,
	forgot,
	reset,
	getDetails,
	mobileverification,
	updateIp,
	getuplstatuses,
	getAdminTxes,
	logout,
	getall,
	search,
	getitems,
	lazy,
};
