import React, { Component } from "react";
import Collapsible from "react-collapsible";
import Header from "../common/header";
import cart from "../../services/cartService";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import get_address from "../../redux/actions/getaddressActions";

var Joi = require("joi-browser");

const schema = Joi.object().keys({
  fullname: Joi.string().required(),
  doornumber: Joi.string().required(),
  street: Joi.string().required(),
  area: Joi.string().required(),
  landmark: Joi.string().required(),
  city: Joi.string().required(),
  state: Joi.string().required(),
  pincode: Joi.string().required(),
  mobile: Joi.string().min(4).max(10).required(),
});
class Checkout extends Component {
  state = {
    fullname: "",
    doornumber: "",
    street: "",
    area: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    mobile: "",
    updata: {},
    update: false,
    Indstates: [
      { id: 0, value: "State" },
      { id: 1, value: "Jammu and Kashmir" },
      { id: 2, value: "Punjab" },
      { id: 3, value: "Himachal Pradesh" },
      { id: 4, value: "Haryana" },
      { id: 5, value: "Delhi" },
      { id: 6, value: "Rajasthan" },
      { id: 7, value: " Uttar Pradesh" },
      { id: 8, value: "Uttarakhand" },
      { id: 9, value: " Madhya Pradesh" },
      { id: 10, value: "Chattisgarh" },
      { id: 11, value: "Gujarat" },
      { id: 12, value: "Maharashtra" },
      { id: 13, value: "Karnataka" },
      { id: 14, value: "Goa" },
      { id: 15, value: "Kerala" },
      { id: 16, value: "Tamil nadu" },
      { id: 17, value: "Andhra pradesh" },
      { id: 18, value: "Telangana" },
      { id: 19, value: "Orissa" },
      { id: 20, value: "Bihar" },
      { id: 21, value: "Jharkhand" },
      { id: 22, value: "West Bengal" },
      { id: 23, value: "Assam" },
      { id: 24, value: "Arunach Pradesh" },
      { id: 25, value: "Sikkim" },
      { id: 26, value: "Meghalaya" },
      { id: 27, value: "Mizoram" },
      { id: 28, value: "Nagaland" },
      { id: 29, value: "Tripura" },
    ],
    value: "",
    disabled: false,
  };
  componentDidMount = async () => {
    get_address();
  };
  handleChange = async (evt) => {
    await this.setState({
      [evt.target.name]: evt.target.value,
      value: evt.target.value,
    });
  };
  addaddress = async () => {
    this.setState({ disabled: !this.state.disabled });
    toast.configure();
    const {
      fullname,
      doornumber,
      street,
      area,
      landmark,
      city,
      state,
      pincode,
      mobile,
    } = this.state;

    let val = "";
    const validata = Joi.validate(
      {
        fullname,
        doornumber,
        street,
        area,
        landmark,
        city,
        state,
        pincode,
        mobile,
      },
      schema,
      function (err, value) {
        if (!err) return null;
        const reterr = err.details[0].message;
        val = err.details[0].context.key;
        return reterr;
      }
    );
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setTimeout(async () => {
        if (val === "fullname") {
          this.setState({ fullname: "" });
        } else if (val === "doornumber") {
          this.setState({ doornumber: "" });
        } else if (val === "street") {
          this.setState({ street: "" });
        } else if (val === "area") {
          this.setState({ area: "" });
        } else if (val === "landmark") {
          this.setState({ landmark: "" });
        } else if (val === "city") {
          this.setState({ city: "" });
        } else if (val === "state") {
          this.setState({ state: "" });
        } else if (val === "pincode") {
          this.setState({ pincode: "" });
        } else if (val === "mobile") {
          this.setState({ mobile: "" });
        }
      }, 2000);
    } else {
      try {
        const obj = {
          fullname: this.state.fullname,
          drnum: this.state.doornumber,
          street: this.state.street,
          area: this.state.area,
          city: this.state.city,
          state: this.state.state,
          pincode: this.state.pincode,
          other: this.state.landmark,
          contact: this.state.mobile,
        };

        const data = await cart.addaddress(obj);
        if (data.data.success) {
          toast.success(data.data.success, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          get_address();
          setTimeout(async () => {
            await this.setState({
              fullname: "",
              doornumber: "",
              street: "",
              area: "",
              landmark: "",
              city: "",
              state: "",
              pincode: "",
              mobile: "",
            });
          }, 2000);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({
              fullname: "",
              doornumber: "",
              street: "",
              area: "",
              landmark: "",
              city: "",
              state: "",
              pincode: "",
              mobile: "",
            });
          }, 2000);
        }
      }
    }
  };
  changeaddress = async (item, event) => {
    toast.configure();
    if (this.state.value === item.adrsid) {
      await this.setState({ update: true, updata: item });
    } else {
      toast.error("Select Address", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  updateaddress = async () => {
    toast.configure();
    const {
      fullname,
      doornumber,
      street,
      area,
      landmark,
      city,
      state,
      pincode,
      mobile,
      updata,
    } = this.state;
    const upobj = {
      adrsid: updata.adrsid,
      fullname: fullname ? fullname : updata.fullname,
      drnum: doornumber ? doornumber : updata.drnum,
      street: street ? street : updata.street,
      area: area ? area : updata.area,
      city: city ? city : updata.city,
      state: state ? state : updata.state,
      pincode: pincode ? pincode : updata.pincode,
      other: landmark ? landmark : updata.other,
      contact: mobile ? mobile : updata.mobile,
    };

    try {
      const data = await cart.updateaddress(upobj);
      if (data) {
        await this.setState({ update: false });
        toast.success("Updated Successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        get_address();
        await this.setState({
          fullname: "",
          doornumber: "",
          street: "",
          area: "",
          landmark: "",
          city: "",
          state: "",
          pincode: "",
          mobile: "",
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTimeout(async () => {
          await this.setState({
            fullname: "",
            doornumber: "",
            street: "",
            area: "",
            landmark: "",
            city: "",
            state: "",
            pincode: "",
            mobile: "",
          });
        }, 2000);
      }
    }
  };

  deleteaddress = async (item, evt) => {
    toast.configure();
    if (this.state.value === item.adrsid) {
      const obj = {
        adrsid: item.adrsid,
      };
      const data = await cart.deleteaddress(obj);
      if (data.data.success) {
        toast.success(data.data.success, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        await get_address();
      }
    } else {
      toast.error("Select Address", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    // toast.configure();
    // if (item) {
    //   const obj = {
    //     adrsid: item.adrsid,
    //   };
    //   const data = await cart.deleteaddress(obj);
    //   if (data.data.success) {
    //     toast.success(data.data.success, {
    //       position: toast.POSITION.BOTTOM_LEFT,
    //     });
    //   }
    // }
  };
  checkoutPage = (item) => {
    this.props.history.push({
      pathname: "/checkout",
      state: { data: item },
    });
  };
  checkoutPage1 = (item) => {
    this.props.history.push({
      pathname: "/checkout1",
      // state: { data: item },
    });
  };
  render() {
    var ss;
    if (this.props.cartlist && this.props.cartlist.length) {
      var tcount = [];
      const tot = this.props.cartlist.map((element) => {
        const t = element.item.map((it, index) => {
          const tt = parseInt(it.dealprice) * parseInt(element.qty);
          tcount.push(tt);
          var sum = tcount.reduce(function (acc, num) {
            acc += num;
            return acc;
          }, 0);

          ss = sum.toFixed(2);
          return tt;
        });
      });
    }

    const states = this.state.Indstates;
    const getaddress = this.props.getaddress;

    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="container">
          <div className="page_sub-header">
            <header className="page_header page__header--stack">
              <h1 className="page_title heading h1"> Checkout </h1>
            </header>
          </div>
        </div>
        <div className="cart-wrapper">
          <div className="cart-wrapper_inner">
            <div className="cart-wrapper_inner-inner">
              <div className="container container--flush">
                <div className="card">
                  <div className="card__header">
                    <h2 className="card__title heading h4">
                      Shipping Address{" "}
                      <hr class="card__separator mt-2 mb-3"></hr>
                    </h2>
                  </div>
                  <div>
                    {/* {getaddress && getaddress.length > 0 ? (
                      getaddress.map((ad, index) => {
                        return ( */}

                    <div
                      // key={index}
                      className="product-list product-list--collection card__section pt-2"
                    >
                      {/* <div className="mr-4">
                        <input
                          onSelect={this.handleChange}
                          type="radio"
                          className="radio"
                          id="filter-3-tag-brand_akg"
                          name="tag-brand"
                          value="checked"
                          checked={this.props.getaddress.adrsid}
                          // onChange={this.handleChange}
                        />
                      </div> */}

                      <div className="row">
                        {getaddress && getaddress.length > 0 ? (
                          getaddress.map((ad, index) => {
                            return (
                              <div class="mb-4">
                                {/* <h2
                                  className="mr-3 ml-3"
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  {ad.fullname}{" "}
                                </h2> */}
                                <div class="d-flex 1/2--desk">
                                  <input
                                    onSelect={this.handleChange}
                                    type="radio"
                                    className="radio ml-3"
                                    id="filter-3-tag-brand_akg"
                                    name="tag-brand"
                                    value={ad.adrsid}
                                    checked={this.props.getaddress.adrsid}
                                    // checked={this.props.getaddress.adrsid}
                                    onChange={this.handleChange}
                                  />
                                  <h3 className="mr-3 ml-3 text-bold text-nowrap">
                                    {" "}
                                    {ad.fullname}{" "}
                                  </h3>
                                </div>
                                <p className="ml-5">
                                  {ad.drnum}, <br /> {ad.street},
                                  <br />
                                  {ad.area},{ad.city},<br /> {ad.state},
                                  {ad.pincode}
                                  <br /> {ad.contact}
                                  <div class="mt-3">
                                    <button
                                      onClick={() => this.changeaddress(ad)}
                                      type="submit"
                                      className="button button--primary btn-sm mr-2"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => {
                                        // if (
                                        //   window.confirm("Delete the address?")
                                        // )
                                        this.deleteaddress(ad);
                                      }}
                                      type="submit"
                                      className="button btn-sm button--secondary"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </p>
                              </div>
                              // </div>
                            );
                          })
                        ) : (
                          <h4 className="text-center mt-4">No Address Found</h4>
                        )}
                      </div>

                      {/* <div className="mt-2 offset-md-3">
                              <button
                                onClick={() => this.changeaddress(ad)}
                                type="submit"
                                className="button button--secondary btn-sm "
                              >
                                Delivery address
                              </button>
                            </div> */}
                    </div>
                    {/* );  }) ) : ( 
                    <h4 className="text-center mt-4">No Address Found</h4>
                     )} */}
                    {!this.state.update ? (
                      <div className="card__section ">
                        <div className="form__header">
                          <h2 className="heading h3">
                            Add new Shipping address{" "}
                          </h2>{" "}
                        </div>
                        <div className="form__input-row">
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="text"
                              className="form__field form__field--text "
                              name="fullname"
                              value={this.state.fullname}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Full Name
                            </label>
                          </div>
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="text"
                              className="form__field form__field--text "
                              name="doornumber"
                              value={this.state.doornumber}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              DOOR.No
                            </label>
                          </div>

                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-email"
                              type="email"
                              className="form__field form__field--text "
                              name="street"
                              value={this.state.street}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-email"
                              className="form__floating-label"
                            >
                              Street
                            </label>
                          </div>
                        </div>
                        <div className="form__input-row">
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-email"
                              type="email"
                              className="form__field form__field--text "
                              name="area"
                              value={this.state.area}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-email"
                              className="form__floating-label"
                            >
                              Area
                            </label>
                          </div>
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="text"
                              className="form__field form__field--text "
                              name="landmark"
                              value={this.state.landmark}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Land Mark
                            </label>
                          </div>

                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-email"
                              className="form__field form__field--text "
                              name="city"
                              value={this.state.city}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-email"
                              className="form__floating-label"
                            >
                              City
                            </label>
                          </div>
                        </div>
                        <div className="form__input-row">
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="number"
                              className="form__field form__field--text "
                              name="mobile"
                              value={this.state.mobile}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Mobile No
                            </label>
                          </div>

                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <div className="select-wrapper select-wrapper--primary">
                              <svg
                                className="icon icon--arrow-bottom"
                                viewBox="0 0 12 8"
                                role="presentation"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-width="2"
                                  d="M10 2L6 6 2 2"
                                  fill="none"
                                  stroke-linecap="square"
                                ></path>
                              </svg>
                              <select
                                value={this.state.state}
                                onChange={this.handleChange}
                                name="state"
                              >
                                {states
                                  ? states.map((st, id) => {
                                      return (
                                        <optgroup key={id}>
                                          <option value={st.value}>
                                            {" "}
                                            {st.value}{" "}
                                          </option>
                                        </optgroup>
                                      );
                                    })
                                  : null}
                              </select>
                            </div>
                          </div>
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="number"
                              className="form__field form__field--text "
                              name="pincode"
                              value={this.state.pincode}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Pin Code
                            </label>
                          </div>
                        </div>
                        <div
                          disabled={this.state.disabled}
                          onClick={this.addaddress}
                          className="form__submit  button button--primary button--min-width"
                        >
                          {" "}
                          Save & Continue to shipping
                        </div>
                      </div>
                    ) : (
                      <div className="card__section ">
                        <div className="form__header">
                          {" "}
                          <h2 className="heading h3">
                            {" "}
                            Update Shipping address{" "}
                          </h2>{" "}
                        </div>
                        <div className="form__input-row">
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="text"
                              className="form__field form__field--text "
                              name="fullname"
                              placeholder={this.state.updata.fullname}
                              value={this.state.fullname}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Full Name
                            </label>
                          </div>
                        </div>
                        <div className="form__input-row">
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="text"
                              className="form__field form__field--text "
                              name="doornumber"
                              placeholder={this.state.updata.drnum}
                              value={this.state.doornumber}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              DOOR.No
                            </label>
                          </div>

                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-email"
                              type="email"
                              className="form__field form__field--text "
                              name="street"
                              placeholder={this.state.updata.street}
                              value={this.state.street}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-email"
                              className="form__floating-label"
                            >
                              Street
                            </label>
                          </div>
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-email"
                              type="email"
                              className="form__field form__field--text "
                              name="area"
                              placeholder={this.state.updata.area}
                              value={this.state.area}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-email"
                              className="form__floating-label"
                            >
                              Area
                            </label>
                          </div>
                        </div>
                        <div className="form__input-row">
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="text"
                              className="form__field form__field--text "
                              name="landmark"
                              placeholder={this.state.updata.other}
                              value={this.state.landmark}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Land Mark
                            </label>
                          </div>

                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-email"
                              className="form__field form__field--text "
                              name="city"
                              placeholder={this.state.updata.city}
                              value={this.state.city}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-email"
                              className="form__floating-label"
                            >
                              City
                            </label>
                          </div>
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <div className="select-wrapper select-wrapper--primary">
                              <svg
                                className="icon icon--arrow-bottom"
                                viewBox="0 0 12 8"
                                role="presentation"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-width="2"
                                  d="M10 2L6 6 2 2"
                                  fill="none"
                                  stroke-linecap="square"
                                ></path>
                              </svg>
                              <select
                                value={this.state.state}
                                onChange={this.handleChange}
                                placeholder={this.state.updata.state}
                                name="state"
                              >
                                {states
                                  ? states.map((st, id) => {
                                      return (
                                        <optgroup key={id}>
                                          <option
                                            value={
                                              st.value === "State"
                                                ? this.state.updata.state
                                                : st.value
                                            }
                                          >
                                            {" "}
                                            {st.value === "State"
                                              ? this.state.updata.state
                                              : st.value}{" "}
                                          </option>
                                        </optgroup>
                                      );
                                    })
                                  : null}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form__input-row">
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="number"
                              className="form__field form__field--text "
                              placeholder={this.state.updata.pincode}
                              name="pincode"
                              value={this.state.pincode}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Pin Code
                            </label>
                          </div>
                          <div className="form__input-wrapper form__input-wrapper--labelled">
                            <input
                              id="contact-form-name"
                              type="number"
                              className="form__field form__field--text "
                              name="mobile"
                              placeholder={this.state.updata.contact}
                              value={this.state.mobile}
                              onChange={this.handleChange}
                            />
                            <label
                              for="contact-form-name"
                              className="form__floating-label"
                            >
                              Mobile No
                            </label>
                          </div>
                        </div>

                        {/* <div className="form__input-wrapper form__input-wrapper--labelled">
													<div className="checkbox-wrapper">
														<input
															type="checkbox"
															className="checkbox"
															name="tag-price"
														/>
														<svg
															className="icon icon--check"
															viewBox="0 0 24 24"
															role="presentation"
														>
															<path
																fill="currentColor"
																d="M9 20l-7-7 3-3 4 4L19 4l3 3z"
															></path>
														</svg>
													</div>
													<label>Save this information for next time </label>
												</div> */}
                        <div
                          disabled={this.state.disabled}
                          onClick={this.updateaddress}
                          className="form__submit  button button--primary button--min-width"
                        >
                          {" "}
                          Update
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="cart-recap ">
                  <div className="cart-recap_scroller">
                    <div className="card">
                      <div className="card_section">
                        <div className="cart-recap_price-line text--pull">
                          <span className="cart-recap_price-line-label">
                            {" "}
                            Items(
                            {this.props.cartlist
                              ? this.props.cartlist.length
                              : 0}
                            )
                          </span>
                          <span className="cart-recap_price-line-price ">
                            ₹{ss}
                          </span>
                        </div>
                        <hr className="card__separator mt-2 mb-3" />
                        <div className="cart-recap_price-line text--pull">
                          <span className="cart-recap_price-line-label">
                            {" "}
                            Shipping & Handling{" "}
                          </span>
                          <span className="cart-recap_price-line-price">
                            ₹10.95
                          </span>
                        </div>
                        <hr className="card__separator mt-2 mb-3" />
                        <div className="cart-recap_price-line text--pull">
                          <span className="cart-recap_price-line-label">
                            {" "}
                            Estimated Tax{" "}
                          </span>
                          <span className="cart-recap_price-line-price">
                            ₹1.95
                          </span>
                        </div>
                        <hr className="card__separator mt-2 mb-3" />
                        <div className="cart-recap_price-line text--pull">
                          <span className="h4 text-bold theme-text">
                            {" "}
                            Total Amount{" "}
                          </span>
                          <span className="h4 text-bold theme-text">₹{ss}</span>
                        </div>
                        <div className="cart-recap_notices rte"></div>
                        <button
                          onClick={this.checkoutPage1()}
                          type="submit"
                          className="button button--primary button--full"
                        >
                          {" "}
                          Checkout
                        </button>
                        <div className="cart-recap_note">
                          <button
                            type="button"
                            className="cart-recap__note-button"
                            data-action="toggle-collapsible"
                            aria-controls="order-note"
                            aria-expanded="false"
                          >
                            Payment Method{" "}
                            <span>
                              <span className="cart-recap__note-edit ">
                                Edit
                              </span>{" "}
                              <i className="fas fa-angle-down"> </i>{" "}
                            </span>
                          </button>
                          <div
                            id="order-note"
                            className="collapsible"
                            aria-hidden="true"
                          >
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getaddress: state.getaddress,
    cartlist: state.cartlist,
  };
};
export default connect(mapStateToProps)(Checkout);
