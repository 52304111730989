import { combineReducers } from "redux";

import authReducer from "./authReducer";

import profileReducer from "./profileReducer";
import addcartReducer from "./addcartReducer";
import cartlistReducer from "./cartlistReducer";
import getproductsReducer from "./getproductsReducer";
import allproductsReducer from "./allproductsReducer";
import getcategorysReducer from "./getCategoryReducer";
import getitemsReducer from "./getitemsReducer";
import getaddressreducer from "./getaddressReducer";

export default function allReducers() {
	return combineReducers({
		auth: authReducer,
		addcart: addcartReducer,
		cartlist: cartlistReducer,
		getproducts: getproductsReducer,
		getprofile: profileReducer,
		allproducts: allproductsReducer,
		getcategorys: getcategorysReducer,
		getitems: getitemsReducer,
		getaddress: getaddressreducer,
	});
}
