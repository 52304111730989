import http from "../services/httpservice";
const apiEndpoint = "/userauth";
// const tokenKey = "token";

http.setJwt(getJwt());
// function cartUrl(id) {
// 	return `₹{apiurl}/₹{id}`;
// }
// export function getCart() {
// 	return http.get(apiurl);
// }
// export function addCart(productid) {
// 	return http.get(cartUrl(productid));
// }
// export function updateCart(cartid) {
// 	return http.post("/products", cartid);
// }
export function getJwt() {
	return localStorage.getItem("token");
}
export async function addcart(obj) {
	return await http.post(apiEndpoint + "/adcart", obj);
}
export async function cartlist() {
	return await http.post(apiEndpoint + "/cartlist");
}
export async function updatecart(obj) {
	return await http.post(apiEndpoint + "/upcart", obj);
}
export function deletecart(cartid) {
	return http.post("/userauth/decart", cartid);
}
export function getaddress() {
	return http.post("/userauth/getadrs");
}
export function addaddress(obj) {
	return http.post("/userauth/adadrs", obj);
}
export function updateaddress(obj) {
	return http.post("/userauth/upadrs", obj);
}
export function deleteaddress(adid) {
	return http.post("/userauth/deladrs", adid);
}
export default {
	getJwt,
	addcart,
	cartlist,
	updatecart,
	deletecart,
	getaddress,
	addaddress,
	updateaddress,
	deleteaddress,
};
