import React, { Component } from "react";
// import { getCategories } from "../../services/categoryService";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-safe";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";

class RecentProducts extends Component {
  state = {
    categories: [],
    loader: false,
  };
  async componentDidMount() {}
  render() {
    return (
      <React.Fragment>
        <div className="shopify-section">
          <section className="section" data-section-type="collection-list">
            <div className="container">
              <header className="section_header">
                <h2 className="section_title heading h3">Popular categories</h2>
                <Link to="/allproducts3" className="section_action-link link">
                  View all
                  <svg
                    className="icon icon--tail-right"
                    viewBox="0 0 24 24"
                    role="presentation"
                  >
                    <path
                      fill="currentColor"
                      d="M22.707 11.293L15 3.586 13.586 5l6 6H2c-.553 0-1 .448-1 1s.447 1 1 1h17.586l-6 6L15 20.414l7.707-7.707c.391-.391.391-1.023 0-1.414z"
                    ></path>
                  </svg>
                </Link>
              </header>

              <div className="scroller">
                <div className="scroller_inner">
                  <div className="collection-list">
                    <OwlCarousel
                      key={`carousel_${
                        this.props.getcategorys
                          ? this.props.getcategorys.length
                          : 0
                      }`}
                      autoPlay={true}
                      nav={false}
                      responsiveClass={true}
                      items={6}
                      margin={45}
                    >
                      {
                        this.props.getcategorys &&
                        this.props.getcategorys.length > 0
                          ? this.props.getcategorys.map((cp) => {
                              return (
                                <div
                                  key={cp._id}
                                  href="#"
                                  className="collection-item"
                                  data-collection-index="0"
                                  style={{ width: "155px" }}
                                >
                                  <div
                                    className="collection-item_image-wrapper collection-item_image-wrapper--rounded"
                                    style={{
                                      background: "#e7ebee",
                                      border: "3px solid #e1e3e4",
                                    }}
                                  >
                                    <div
                                      className="aspect-ratio"
                                      style={{ paddingBottom: "100%" }}
                                    >
                                      <img
                                        className="lazyload image--fade-in cat-image"
                                        src={require(`../../assets/images/${cp.category}.jpg`)}
                                        alt="img"
                                      />
                                    </div>
                                  </div>
                                  <Link
                                    to={{
                                      pathname: "/catproducts",
                                      type: "category",
                                      p: cp,
                                      ie: cp,
                                    }}
                                  >
                                    <p className="text-center collection-item__title text--strong">
                                      {cp.category}
                                      <svg
                                        className="icon icon--tail-right"
                                        viewBox="0 0 24 24"
                                        role="presentation"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M22.707 11.293L15 3.586 13.586 5l6 6H2c-.553 0-1 .448-1 1s.447 1 1 1h17.586l-6 6L15 20.414l7.707-7.707c.391-.391.391-1.023 0-1.414z"
                                        ></path>
                                      </svg>
                                    </p>
                                  </Link>
                                </div>
                              );
                            })
                          : null
                        // (
                        //   <div>
                        //     <img
                        //       src={require("../../assets/images/loader.gif")}
                        //       alt="loader"
                        //       className=""
                        //     />
                        //   </div>
                        // )
                      }
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getcategorys: state.getcategorys,
  };
};
export default connect(mapStateToProps)(RecentProducts);
