import { GET_PRODUCTS } from "../actions/getproductsActions";

const getproductsReducer = (state = null, { type, payload }) => {
	switch (type) {
		case GET_PRODUCTS:
			return payload;
		default:
			return state;
	}
};
export default getproductsReducer;
