import store from "../index";
import auth from "../../services/authService";

export var GET_CATEGORYS = "GET_CATEGORYS";

function get_categorys() {
  setTimeout(async () => {
    try {
      const authy = await auth.getcategories();
      store.dispatch({ type: GET_CATEGORYS, payload: authy.data });
    } catch (error) {}
  }, 300);
}

export default get_categorys;
