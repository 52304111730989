import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import cart_list from "../../redux/actions/cartlistActions";
import cart from "../../services/cartService";
import OwlCarousel from "react-owl-carousel-safe";

// import Collapsible from "react-collapsible";
// import get_categorys from "../../redux/actions/getCategorysActions";
import Header from "../common/header";
// import { Link, Redirect } from "react-router-dom";
import Allcats from "../common/allcats";

class SearchProducts extends Component {
  state = {
    getproducts: [],
    quantity: "1",
  };

  componentDidMount = async () => {
    // if (this.props.getcategorys && this.props.getcategorys.length <= 0) {
    // 	await get_categorys();
    // }

    if (this.props.location.state.type === "search") {
      await this.setState({ getproducts: this.props.location.state.data });
    } else {
      this.props.history.push("/home");
    }
  };
  productpage = (p) => {
    this.props.history.push({
      pathname: "/productdetails",
      state: { data: p },
    });
  };
  addcart = async (item) => {
    console.log(item);
    toast.configure();
    const obj = {
      itemid: item.itemid,
      size: "20",
      qty: parseInt(this.state.quantity),
    };
    try {
      const data = await cart.addcart(obj);

      if (data.data.success) {
        await cart_list();
        toast.success(data.data.success, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Please Login your account", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    }
  };

  render() {
    const getproducts = this.props.location.state.data
      ? this.props.location.state.data
      : this.props.allproducts;

    // const gp = this.props.allproducts.filter(
    //   (i) => i.categoryid === this.props.location.item._id
    // );
    const gp = this.props.allproducts ? this.props.allproducts : null;

    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div id="shopify-section-product-template" className="shopify-section">
          <div className="container container--flush">
            <div className="page_sub-header"></div>
            <div className="layout">
              <div className="layout_section layout_section--secondary hidden-pocket">
                <Allcats />
              </div>
              <div class="layout__section">
                {/* <allproducts2 /> */}
                {/* <allproducts/> */}
                <div
                  className="collection__image-wrapper collection__image-wrapper--medium"
                  style={{
                    backgroundImage:
                      "url(https://www.artisstore.com/media/responsivebannerslider/artiswebsitebanner3_1570619470.jpg)",
                  }}
                ></div>
                <div className="card ">
                  <header className="card__header card__header--tight">
                    <div className="collection__header ">
                      <div className="collection__header-inner">
                        <div className="collection__meta">
                          <div className="collection__meta-inner">
                            <h1 className="collection__title heading h1">
                              {/* {this.props.location.state.type === "search" ? (
                                this.props.history.location.p ? (
                                  this.props.history.location.p.category
                                ) : (
                                  <Redirect to="/" />
                                )
                              ) : this.props.location.p ? (
                                this.props.location.p.subsubcatname
                              ) : (
                                <Redirect to="/" />
                              )} */}
                              Showing 1- {getproducts.length} products
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="collection__toolbar ">
                    {/* <div className="collection__toolbar-item collection__toolbar-item--count hidden-pocket">
											<span className="collection__showing-count hidden-pocket hidden-lap">
												Showing 1 - 24 of 75 products
											</span>
										</div> */}
                    <div className="collection__toolbar-item collection__toolbar-item--sort">
                      <div className="value-picker-wrapper">
                        <button
                          className="value-picker-button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-controls="sort-by-selector"
                          data-action="open-value-picker"
                        >
                          {/* <span className="hidden-phone">
														Sort by: Best selling{" "}
														<i className="fas fa-angle-down"> </i>{" "}
													</span>{" "} */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-list product-list--collection product-list--with-sidebar">
                  {!!getproducts ? (
                    getproducts.map((p, index) => {
                      return (
                        <div
                          style={{ cursor: "pointer" }}
                          key={index}
                          // onClick={() => this.productpage(p)}
                          className="product-item product-item--vertical 1/4--desk"
                        >
                          <div className="product-item__image-wrapper ">
                            <div className="aspect-ratio aspect-ratio--square">
                              <img
                                onClick={() => this.productpage(p)}
                                className="product-item__primary-image lazyload "
                                src={p.image[0]}
                                alt="img"
                              />
                            </div>
                          </div>
                          <div className="product-item__info">
                            <div className="product-item__info-inner">
                              <p className="product-item__title text--strong link">
                                {" "}
                                {p.title.slice(0, 21)}
                              </p>

                              <div class="product-item_price-list price-list">
                                <span class="price price--highlight">
                                  ₹{p.mrp}
                                </span>
                                <span class="price price--compare">
                                  ₹{p.dealprice}
                                </span>
                              </div>
                              {/* <div className="product-item__price-list price-list mb-2">
                                <span
                                  style={{ fontSize: "14px", color: "red" }}
                                  className="price price--compare"
                                >
                                  MRP:₹{p.mrp}
                                </span>
                                &nbsp; &nbsp; &nbsp;
                                <span
                                  style={{ fontSize: "14px", color: "blue" }}
                                >
                                  Offer: ₹{p.dealprice}
                                </span>
                              </div> */}
                            </div>
                            <br />
                            <button
                              type="button"
                              className="product-item_action-button  button--small button--primary"
                            >
                              {" "}
                              Buy Now{" "}
                            </button>
                            <button
                              onClick={() => this.addcart(p)}
                              type="button"
                              className="product-item_action-button button button--small button--ternary "
                            >
                              {" "}
                              Add to Cart{" "}
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="offset-5">
                      <img
                        src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                        alt="loader"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="shopify-section">
            <section
              className="section"
              data-section-type="featured-collection"
              data-section-settings="{ }"
            >
              <div className="container">
                <header className="section_header">
                  <div className="section_header-stack">
                    <h2 className="section_title heading h3">Recommended</h2>
                  </div>
                  <a
                    href="collections/sales.html"
                    className="section_action-link link"
                  >
                    View all sales
                    <svg
                      className="icon icon--tail-right"
                      viewBox="0 0 24 24"
                      role="presentation"
                    >
                      <path
                        fill="currentColor"
                        d="M22.707 11.293L15 3.586 13.586 5l6 6H2c-.553 0-1 .448-1 1s.447 1 1 1h17.586l-6 6L15 20.414l7.707-7.707c.391-.391.391-1.023 0-1.414z"
                      ></path>
                    </svg>
                  </a>
                </header>
              </div>

              <div className="container ">
                <div className="scroller">
                  <div className="scroller_inner">
                    <OwlCarousel
                      autoPlay={true}
                      key={`carousel_${
                        this.props.getproducts ? this.props.getproducts : 0
                      }`}
                      nav={false}
                      responsiveClass={true}
                      items={5}
                      margin={0}
                      // autoWidth={true}
                      loop={true}
                    >
                      {gp &&
                        gp.slice(0, 10).map((p, index) => {
                          return (
                            <div class="product-list product-list--vertical product-list--scrollable ">
                              <div
                                key={p._id}
                                class="product-item product-item--vertical"
                                style={{ width: "100%" }}
                              >
                                <div class="product-item_label-list">
                                  <span class="product-label product-label--on-sale">
                                    Save
                                    <span>₹800</span>
                                  </span>
                                </div>
                                <a href="" class="product-item_image-wrapper ">
                                  <div class="aspect-ratio aspect-ratio--square">
                                    <img
                                      class="product-item_primary-image lazyload "
                                      src={p.image}
                                      // src={require("../../assets/images/products/pro1.jpg")}
                                    />
                                  </div>
                                </a>

                                <div class="product-item_info">
                                  <div class="product-item_info-inner">
                                    <a class="product-item_vendor link" href="">
                                      Sony
                                    </a>
                                    <a
                                      href=""
                                      class="product-item_title text--strong link"
                                    >
                                      Sony BRAVIA 4K HDR Ultra HD TV
                                    </a>
                                    <div class="product-item_price-list price-list">
                                      <span class="price price--highlight">
                                        ₹1,398
                                      </span>
                                      <span class="price price--compare">
                                        ₹2,198
                                      </span>
                                    </div>
                                    <span class="product-item_inventory inventory inventory--high">
                                      In stock, 80 units
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allproducts: state.getproducts,
    getproducts: state.getproducts,
    getcategorys: state.getcategorys,
    getitems: state.getitems,
  };
};

export default connect(mapStateToProps)(SearchProducts);
