import React, { Component } from "react";
import { toast } from "react-toastify";
import users from "../../services/userservice";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
	email: Joi.string().email().required(),
});
class ForgotPassword extends Component {
	state = { email: "", isactivate: false, token: "" };
	handleChange = async (evt) => {
		await this.setState({ [evt.target.name]: evt.target.value });
	};
	reset = async () => {
		toast.configure();
		const { email } = this.state;
		const validata = Joi.validate({ email }, schema, function (err, value) {
			if (!err) return null;
			const reterr = err.details[0].message;
			return reterr;
		});
		if (!!validata) {
			toast.error(validata, {
				position: toast.POSITION.BOTTOM_LEFT,
			});

			setTimeout(async () => {
				await this.setState({
					email: "",
				});
			}, 2000);
		} else {
			try {
				const obj = {
					email: email,
				};
				const data = await users.forgot(obj);
				if (data.data) {
					this.setState({ token: data.data });
					this.props.history.push({
						pathname: "/resetpassword",
						state: { token: data.data, type: "reset" },
					});
				}
			} catch (ex) {
				if (ex.response && ex.response.status === 400) {
					toast.error(ex.response.data, {
						position: toast.POSITION.BOTTOM_LEFT,
					});

					setTimeout(async () => {
						await this.setState({ email: "" });
					}, 2000);
				}
			}
		}
	};
	activate = async () => {
		toast.configure();

		try {
			const obj = {
				token: this.state.token,
			};
			const data = await users.activate(obj);

			if (data.data.success) {
				toast.success(data.data.success, {
					position: toast.POSITION.BOTTOM_LEFT,
				});
				this.props.history.push({
					pathname: "/resetpassword",
					state: { token: this.state.token },
				});
			}
		} catch (error) {}
	};
	render() {
		return (
			<React.Fragment>
				<div class="site-account" id="CustomerLoginForm">
					<div>
						<div class="title-wrapper text-center mt-4">
							<ol className="breadcrumbs"></ol>
							<h1
								className="page-title"
								style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
							>
								Verification
							</h1>
						</div>
						<div class="form-group ">
							<label for="customer_login-email">
								Email address<span class="required">*</span>
							</label>
							<input
								onChange={this.handleChange}
								value={this.state.email}
								type="email"
								name="email"
							/>
						</div>
						<div class="form-group">
							<button
								onClick={this.reset}
								type="submit"
								class="btn btn-primary w100"
							>
								Reset
							</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ForgotPassword;
