import store from "../index";
import cart from "../../services/cartService";

export var ADD_CART = "ADD_CART";

function add_cart() {
	setTimeout(async () => {
		try {
			const authy = await cart.addcart();
			store.dispatch({ type: ADD_CART, payload: authy });
		} catch (error) {}
	}, 100);
}

export default add_cart;
