import { GET_CATEGORYS } from "../actions/getCategorysActions";

const getcategorysReducer = (state = null, { type, payload }) => {
  switch (type) {
    case GET_CATEGORYS:
      return payload;
    default:
      return state;
  }
};
export default getcategorysReducer;
