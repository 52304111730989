import auth from "../../services/authService";
import store from "../index";
export var GET_PROFILE = "GET_PROFILE";

function get_profile() {
	setTimeout(async () => {
		const gentxhis = await auth.getprofile();
		store.dispatch({ type: GET_PROFILE, payload: gentxhis.data });
	}, 100);
}

export default get_profile;
