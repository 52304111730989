import store from "../index";
import auth from "../../services/authService";
import user from "../../services/userservice";

export var GET_ITEMS = "GET_ITEMS";

function get_items() {
	setTimeout(async () => {
		try {
			const authy = await user.getitems();
			store.dispatch({ type: GET_ITEMS, payload: authy.data });
		} catch (error) {}
	}, 100);
}

export default get_items;
