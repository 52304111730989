import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import ReactImageZoom from "react-image-zoom";
import Header from "../common/header";
import cart from "../../services/cartService";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import cart_list from "../../redux/actions/cartlistActions";
import ReactImageMagnify from "react-image-magnify";
import largeImage from "../../assets/images/largeimg.jpg";
import OwlCarousel from "react-owl-carousel-safe";

// import Carousel from "nuka-carousel";

class ProductDetails extends Component {
	state = {
		product: {},
		image: "",
		quantity: "1",
		disabled: false,
		alwaysInPlace: false,
		overlayOpacity: 0.6,
		switchSides: false,
		fillAvailableSpace: false,
		fillAlignTop: false,
		fillGapLeft: 0,
		fillGapRight: 10,
		fillGapTop: 10,
		fillGapBottom: 10,
	};
	async componentDidMount() {
		await this.setState({ product: this.props.history.location.state.data });

		await this.setState({ image: this.state.product.image });
	}
	handlechange = async (evt) => {
		await this.setState({ [evt.target.name]: evt.target.value });
	};
	image = async (item) => {
		await this.setState({ image: item });
	};
	addcart = async (item) => {
		this.setState({ disabled: !this.state.disabled });
		toast.configure();

		const obj = {
			itemid: this.state.product.itemid,
			size: "20",
			qty: parseInt(this.state.quantity),
		};

		try {
			const data = await cart.addcart(obj);
			if (data.data.success) {
				await cart_list();
				toast.success(data.data.success, {
					position: toast.POSITION.BOTTOM_LEFT,
				});
			}
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				console.log(ex.response.data);
				toast.error("Please Login to your account!!", {
					position: toast.POSITION.BOTTOM_LEFT,
				});
			}
		}
	};
	productpage = (item) => {
		this.props.history.push({
			pathname: "/productdetails",
			state: { data: item },
		});
	};
	isDisabled() {
		this.setState({ disabled: true });
	}

	render() {
		const { product } = this.state;
		const gp =
			this.props.allproducts && this.props.history.location.state.data
				? this.props.allproducts.filter(
						(i) =>
							i.categoryid ===
							this.props.history.location.state.data.categoryid,
				  )
				: null;
		return (
			<React.Fragment>
				<Header history={this.props.history} />
				<div id="shopify-section-product-template" class="shopify-section">
					<div class="container">
						<div class="card mt-5">
							<div>
								<div class="sidenav ml-4 row">
									<div className="scroller_inner mt-5 ">
										<div className="product-gallery_thumbnail-list float-left">
											{!!this.state.product.image
												? this.state.product.image.map((item, index) => {
														return (
															<div
																onClick={() => this.image(item)}
																className="product-gallery__thumbnail"
															>
																<div
																	className="aspect-ratio"
																	style={{ paddingBottom: "100.0%" }}
																>
																	<img src={item} alt="img" />
																</div>
															</div>
														);
												  })
												: null}
										</div>
										<div className="perimeter">
											<div className="image fluid__image-container mt-3">
												<ReactImageMagnify
													{...{
														enlargedImageClassName: "help",
														smallImage: {
															alt: this.state.image,
															isFluidWidth: true,
															src: this.state.image,
															sizes:
																"(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
														},
														lensStyle: {
															backgroundColor: "transparent",
														},
														largeImage: {
															alt: "",
															src: this.state.image,
															width: 1000,
															height: 1000,
														},
														enlargedImageContainerDimensions: {
															width: "200%",
															height: "100%",
														},
														isHintEnabled: true,
														enlargedImagePosition: "beside",
													}}
												/>
											</div>
										</div>
									</div>
								</div>

								<div class="product-sticky pro-1">
									<div class="card_section ">
										<div class="product-meta">
											<h1 class="product-meta_title heading h1">
												{product.title}
											</h1>
											<div class="product-meta_label-list">
												<span class="product-label product-label--on-sale">
													Save <span>₹{product.mrp - product.dealprice}</span>
												</span>
											</div>
											{/* <div class="product-meta_reference">
                      <a
                        class="product-meta_vendor link link--accented"
                        href="http://saiproj.000webhostapp.com/ware/product.html"
                      >
                        Sony
                      </a>
                      <span class="product-meta_sku">
                        SKU: SON-695219-XBR-5{" "}
                      </span>
                    </div> */}
										</div>

										<hr class="card_separator" />
										<form class="product-form">
											{" "}
											<div class="product-form_variants">
												<div class="product-form_option">
													<span class="product-form_option-name text--strong mt-4">
														Size:
													</span>

													<div class="block-swatch-list">
														<div class="block-swatch">
															<input
																class="block-swatch_radio product-form_single-selector"
																type="radio"
																value=""
															/>
															<label
																class="block-swatch_item"
																for="product-template-1916221128755-1-1"
																title='55"'
															>
																<span class="block-swatch_item-text">L</span>
															</label>
														</div>
														<div class="block-swatch">
															<input
																class="block-swatch_radio product-form_single-selector"
																type="radio"
																name="product-template-1916221128755-1"
															/>
															<label
																class="block-swatch_item"
																for="product-template-1916221128755-1-2"
																title='65"'
															>
																<span class="block-swatch_item-text">M</span>
															</label>
														</div>
													</div>
												</div>
											</div>
											<div class="product-form_info-list">
												<div class="product-form_info-item">
													<span class="product-form_info-title text--strong">
														Price:
													</span>

													<div class="product-form_info-content">
														<div class="price-list">
															<span class="price price--highlight">
																₹{product.dealprice}
															</span>
															<span class="price price--compare">
																₹{product.mrp}
															</span>
														</div>
													</div>
												</div>
												<div class="product-form_info-item">
													<span class="product-form_info-title text--strong">
														Stock:
													</span>
													{product.stock ? (
														<div class="product-form_info-content">
															<span class="product-form_inventory inventory inventory--high">
																In stock {product.stock}
															</span>
														</div>
													) : (
														<div class="product-form_info-content">
															<span class="product-form_inventory inventory inventory--high">
																Out of stock
															</span>
														</div>
													)}
												</div>

												<div class="product-form_info-item product-form_info-item--quantity">
													<label
														for="product-template-1916221128755-quantity"
														class="product-form_info-title text--strong"
													>
														Quantity:
													</label>

													<div class="product-form_info-content">
														<div class="select-wrapper select-wrapper--small select-wrapper--primary">
															<select
																value={this.state.quantity}
																onChange={this.handlechange}
																name="quantity"
																className="product-form_quantity"
															>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
																<option value="10">10+</option>
															</select>
														</div>
													</div>
												</div>
											</div>
											<div class="product-form_payment-container">
												<button
													type="button"
													disabled={this.state.disabled}
													onClick={this.addcart}
													className="form__submit  button button--primary button--min-width"
													// "product-form_add-button button button--primary"
												>
													Add to cart
												</button>
												{/* <div
                          disabled={this.state.disabled}
                          onClick={this.addcart}
                          className="product-form_add-button button button--primary"
                        >
                          Add to cart
                        </div> */}
											</div>
										</form>
									</div>

									<div class="card__header pt-0">
										<h2 class="card__title heading h3 "> Available offers </h2>
										<hr class="card_separator mb-0 " />
									</div>

									<div class="card__section expandable-content">
										<div class="rte text--pull">
											<ul>
												<li>
													{" "}
													Bank Offer5% Unlimited Cashback on Flipkart Axis Bank
													Credit Card (T&amp;C){" "}
												</li>
												<li>
													{" "}
													Bank Offer10% off* with Axis Bank Buzz Credit Card
													T&amp;C{" "}
												</li>
												<li>
													{" "}
													No cost EMI ₹2,291/month. Standard EMI also available
													&nbsp;{" "}
													<a href="http://saiproj.000webhostapp.com/ware/product.html">
														{" "}
														View Plans <i class="fa fa-angle-right" />{" "}
													</a>{" "}
												</li>
												<li>
													{" "}
													Get upto ₹8400 off on exchange &nbsp;{" "}
													<a href="http://saiproj.000webhostapp.com/ware/product.html">
														{" "}
														Buy with Exchange <i class="fa fa-angle-right" />{" "}
													</a>{" "}
												</li>
											</ul>
										</div>
									</div>

									<div class="card__header pt-0">
										<h2 class="card__title heading h3 "> Highlights </h2>
										<hr class="card_separator mb-0 " />
									</div>

									<div class="card__section expandable-content">
										<div class="rte text--pull">
											<ul>
												<li> 15.6 inch HD LED Backlit Truelife Display </li>
												<li>
													Once your product has shipped, it usually takes 2 to 3
													business days in USA, 3 to 8 in Europe.
												</li>
												<li>
													You can return your product up to 14 days after
													receiving your order.{" "}
												</li>
											</ul>
										</div>
									</div>

									<div class="card__header pt-0">
										<h2 class="card__title heading h3 "> Specifications </h2>
										<hr class="card_separator mb-0 " />
									</div>

									<table class="table mb-3">
										<tbody>
											<tr class="border-t-0">
												<td class=" pb-0">
													<span> Sales Package </span>
												</td>
												<td class="line-item__title link text--strong  pb-0">
													{" "}
													Laptop, Power Adaptor, User Guide, Warranty Documents{" "}
												</td>
											</tr>
											<tr class="border-t-0">
												<td class=" pb-0">
													<span> Model Number </span>
												</td>
												<td class="line-item__title link text--strong  pb-0">
													{" "}
													vos / vostro 3581{" "}
												</td>
											</tr>

											<tr class="border-t-0">
												<td class=" pb-0">
													<span> Sales Package </span>
												</td>
												<td class="line-item__title link text--strong  pb-0">
													{" "}
													Laptop, Power Adaptor, User Guide, Warranty Documents{" "}
												</td>
											</tr>
											<tr class="border-t-0">
												<td class=" pb-0">
													<span> Model Number </span>
												</td>
												<td class="line-item__title link text--strong  pb-0">
													{" "}
													vos / vostro 3581{" "}
												</td>
											</tr>

											<tr class="border-t-0">
												<td class=" pb-0">
													<span> Sales Package </span>
												</td>
												<td class="line-item__title link text--strong  pb-0">
													{" "}
													Laptop, Power Adaptor, User Guide, Warranty Documents{" "}
												</td>
											</tr>
											<tr class="border-t-0">
												<td class=" pb-0">
													<span> Model Number </span>
												</td>
												<td class="line-item__title link text--strong  pb-0">
													{" "}
													vos / vostro 3581{" "}
												</td>
											</tr>
										</tbody>
									</table>

									<div class="card__header pt-0">
										<h2 class="card__title heading h3 ">
											{" "}
											Ratings &amp; Reviews{" "}
										</h2>
										<hr class="card_separator mb-0 " />
									</div>

									<div class="card__section expandable-content">
										<div class="rte text--pull">
											<h4>
												{" "}
												<span class="rating-badge-normal">
													{" "}
													4.5 <i class="fa fa-star"> </i>{" "}
												</span>{" "}
												Best choice in under 27000 for 15.6 inch catagory{" "}
											</h4>
											<p>
												{" "}
												It's very beautifully and elegantly designed laptop that
												I have got till now. CPU vents are put behind the
												laptop. Battery is not removable and encased inside the
												bottom panel. Overall it's very neatly build into a very
												slim formfactor. Display is average since it's HD.
												touchpad is average.
											</p>
										</div>
									</div>

									<div class="card__section expandable-content">
										<div class="rte text--pull">
											<h4>
												{" "}
												<span class="rating-badge-low">
													{" "}
													2.0 <i class="fa fa-star"> </i>{" "}
												</span>{" "}
												Too Bad Product{" "}
											</h4>
											<p>
												{" "}
												It's very beautifully and elegantly designed laptop that
												I have got till now. CPU vents are put behind the
												laptop. Battery is not removable and encased inside the
												bottom panel. Overall it's very neatly build into a very
												slim formfactor. Display is average since it's HD.
												touchpad is average.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="shopify-section">
						<section
							className="section"
							data-section-type="featured-collection"
							data-section-settings="{ }"
						>
							<div className="container">
								<header className="section_header">
									<div className="section_header-stack">
										<h2 className="section_title heading h3"> Recommended </h2>
									</div>
									<a
										href="collections/sales.html"
										className="section_action-link link"
									>
										View all sales
										<svg
											className="icon icon--tail-right"
											viewBox="0 0 24 24"
											role="presentation"
										>
											<path
												fill="currentColor"
												d="M22.707 11.293L15 3.586 13.586 5l6 6H2c-.553 0-1 .448-1 1s.447 1 1 1h17.586l-6 6L15 20.414l7.707-7.707c.391-.391.391-1.023 0-1.414z"
											/>
										</svg>
									</a>
								</header>

								<OwlCarousel
									autoPlay={true}
									key={`carousel_${gp ? gp : 0}`}
									nav={false}
									responsiveClass={true}
									items={5}
									margin={0}
									// autoWidth={true}
									loop={true}
								>
									{gp &&
										gp.slice(0, 10).map((p, index) => {
											return (
												<div class="product-list product-list--vertical product-list--scrollable ">
													<div
														key={p._id}
														class="product-item product-item--vertical"
														style={{ width: "100%" }}
													>
														<div class="product-item_label-list">
															<span class="product-label product-label--on-sale">
																Save
																<span>₹800</span>
															</span>
														</div>
														<a href="" class="product-item_image-wrapper ">
															<div class="aspect-ratio aspect-ratio--square">
																<img
																	onClick={() => this.productpage(p)}
																	class="product-item_primary-image lazyload "
																	src={p.image}
																	// src={require("../../assets/images/products/pro1.jpg")}
																/>
															</div>
														</a>

														<div class="product-item_info">
															<div class="product-item_info-inner">
																<a class="product-item_vendor link" href="">
																	Sony
																</a>
																<a
																	href=""
																	class="product-item_title text--strong link"
																>
																	Sony BRAVIA 4K HDR Ultra HD TV
																</a>
																<div class="product-item_price-list price-list">
																	<span class="price price--highlight">
																		₹1,398
																	</span>
																	<span class="price price--compare">
																		₹2,198
																	</span>
																</div>
																<span class="product-item_inventory inventory inventory--high">
																	In stock, 80 units
																</span>
															</div>
														</div>
													</div>
												</div>
											);
										})}
								</OwlCarousel>
							</div>
						</section>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		cartlist: state.cartlist,
		allproducts: state.getproducts,
	};
};

export default connect(mapStateToProps)(ProductDetails);
