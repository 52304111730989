import { GET_ADDRESS } from "../actions/getaddressActions";

const getaddressreducer = (state = null, { type, payload }) => {
	switch (type) {
		case GET_ADDRESS:
			return payload;
		default:
			return state;
	}
};
export default getaddressreducer;
