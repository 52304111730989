import React, { Component } from "react";
import { toast } from "react-toastify";
import users from "../../services/userservice";

var Joi = require("joi-browser");

const schema = Joi.object().keys({
	email: Joi.string()
		.email()
		.required(),
});
class RecoverPassword extends Component {
	state = {
		email: "",
		token: "",
	};
	handleChange = async evt => {
		await this.setState({ [evt.target.name]: evt.target.value });
	};
	async componentDidMount() {
		// document.body.addEventListener("didFocus", () => {
		// 	this.setState({ emailverify: false });
		// });
	}
	reset = async () => {
		toast.configure();
		const { email } = this.state;
		const validata = Joi.validate({ email }, schema, function(err, value) {
			if (!err) return null;
			const reterr = err.details[0].message;
			return reterr;
		});
		if (!!validata) {
			toast.error(validata, {
				position: toast.POSITION.BOTTOM_LEFT,
			});

			setTimeout(async () => {
				await this.setState({
					email: "",
				});
			}, 2000);
		} else {
			try {
				const obj = {
					email: email,
				};
				const data = await users.forgot(obj);

				if (data.data) {
					this.setState({ token: data.data });
					this.props.history.push({
						pathname: "/resetpassword",
						state: { token: data.data, type: "reset" },
					});
				}
			} catch (ex) {
				if (ex.response && ex.response.status === 400) {
					toast.error(ex.response.data, {
						position: toast.POSITION.BOTTOM_LEFT,
					});

					setTimeout(async () => {
						await this.setState({ email: "" });
					}, 2000);
				}
			}
		}
	};

	render() {
		return (
			<React.Fragment>
				<div>
					<header className="popover__header">
						<h2 className="popover__title heading">Verification</h2>
						<p className="popover__legend">Enter your E-mail:</p>
					</header>

					<div className="form__input-wrapper form__input-wrapper--labelled">
						<input
							onChange={this.handleChange}
							value={this.state.email}
							name="email"
							className="form__field form__field--text"
						/>
						<label className="form__floating-label">Email</label>
					</div>
					<button
						onClick={this.reset}
						className="form__submit button button--primary button--full"
					>
						Verify
					</button>
				</div>
			</React.Fragment>
		);
	}
}

export default RecoverPassword;
