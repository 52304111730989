import store from "../index";
import auth from "../../services/authService";
export var UPDATE_AUTH = "UPDATE_AUTH";

function update_auth() {
	setTimeout(async () => {
		try {
			const authy = await auth.getCurrentUser();
			store.dispatch({ type: UPDATE_AUTH, payload: authy });
		} catch (error) {}
	}, 100);
}

export default update_auth;	
