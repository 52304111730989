import React from "react";
import "../src/assets/css/main.css";
import { Switch, Route } from "react-router-dom";
import ForgotPassword from "./components/forgotpassword";
import ResetPassword from "./components/resetpassword";
import Home from "./components/home";
import Footer from "./components/common/footer";
// import Activation from "./components/actvate";
import { withRouter } from "react-router";
import ProtectedRoute from "./components/protectedRoute";
import ProductDetails from "./components/products/productdetails";
import Allproducts2 from "./components/products/allproducts2";
import Allproducts from "./components/products/allproducts";
import Allproducts3 from "./components/products/allproducts3";
import Viewcart from "./components/cart/viewcart";
import Checkout from "./components/cart/checkout";
import SearchProducts from "./components/products/searchproducts";
import catproducts from "./components/products/catproducts";
import Checkout1 from "./components/cart/checkout1";
// import Productdetails1 from "./components/products/productdetails1";

function App() {
  return (
    <React.Fragment>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword/:id" component={ResetPassword} />
          <Route exact path="/activation/:id" component={Home} />
          <Route exact path="/productdetails" component={ProductDetails} />
          {/* <Route exact path="/productdetails1" component={Productdetails1} /> */}
          <Route exact path="/viewcart" component={Viewcart} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/checkout1" component={Checkout1} />
          <Route exact path="/allproducts2" component={Allproducts2} />
          <Route exact path="/allproducts" component={Allproducts} />
          <Route exact path="/allproducts3" component={Allproducts3} />
          <Route exact path="/searchproducts" component={SearchProducts} />
          <Route exact path="/catproducts" component={catproducts} />

          <ProtectedRoute path="/home" component={Home} />
        </Switch>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default withRouter(App);
