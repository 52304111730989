import React, { Component } from "react";

import { connect } from "react-redux";
import Allcats from "./allcats";

class Sidebar extends Component {
  state = {
    isOpen: false,
    pic: "",
    open: false,
    bankopen: false,
    loader: false,
  };
  sidebaropen = async () => {
    if (!this.state.isOpen) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    await this.setState({ isOpen: !this.state.isOpen });
  };

  handleOutsideClick = (e) => {
    this.sidebaropen();
  };

  componentDidMount = async () => {};

  render() {
    // const { open } = this.state;
    return (
      <React.Fragment>
        <div style={{ backgroundColor: "white", width: "90%", height: "100%" }}>
          <Allcats />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Sidebar);
