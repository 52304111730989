import { CART_LIST } from "../actions/cartlistActions";

const cartlistReducer = (state = null, { type, payload }) => {
	switch (type) {
		case CART_LIST:
			return payload;

		default:
			return state;
	}
};
export default cartlistReducer;
