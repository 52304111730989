import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div id="shopify-section-text-with-icons" className="shopify-section">
          <section
            className="section section--tight"
            data-section-id="text-with-icons"
            data-section-type="text-with-icons"
          >
            <div className="container container--flush">
              <div className="text-with-icons ">
                <div className="text-with-icons_item" data-block-index="0">
                  <div className="text-with-icons_icon-wrapper">
                    <svg viewBox="0 0 24 24" role="presentation">
                      <g strokeWidth="1.5" fill="none" fillRule="evenodd">
                        <path d="M6.5 3.25l12 6" stroke="#00badb"></path>
                        <path
                          stroke="#1e2d7d"
                          d="M23 7l-10 5L1 6M13 12v11"
                        ></path>
                        <path
                          stroke="#1e2d7d"
                          strokeLinecap="square"
                          d="M23 7v10l-10 6-12-6V6l10-5z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="text-with-icons_content-wrapper">
                    <p className="text-with-icons_title text--strong">
                      Free delivery for ₹80+ orders
                    </p>
                    <div className="text-with-icons_content rte">
                      <p>We deliver in 48 hours max!</p>
                    </div>
                  </div>
                </div>
                <div className="text-with-icons_item" data-block-index="1">
                  <div className="text-with-icons_icon-wrapper">
                    <svg viewBox="0 0 23 24" role="presentation">
                      <g
                        transform="translate(1 1)"
                        strokeWidth="1.5"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path stroke="#00badb" d="M8 4h8v7"></path>
                        <path
                          stroke="#00badb"
                          strokeLinecap="square"
                          d="M11 7L8 4l3-3"
                        ></path>
                        <circle
                          stroke="#1e2d7d"
                          strokeLinecap="square"
                          cx="6"
                          cy="20"
                          r="2"
                        ></circle>
                        <circle
                          stroke="#1e2d7d"
                          strokeLinecap="square"
                          cx="18"
                          cy="20"
                          r="2"
                        ></circle>
                        <path
                          stroke="#1e2d7d"
                          strokeLinecap="square"
                          d="M21 5l-2 10H5L3 0H0"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="text-with-icons_content-wrapper">
                    <p className="text-with-icons_title text--strong">
                      Satisfied or refunded
                    </p>
                    <div className="text-with-icons_content rte">
                      <p>Free returns within 14 days</p>
                    </div>
                  </div>
                </div>
                <div className="text-with-icons_item" data-block-index="2">
                  <div className="text-with-icons_icon-wrapper">
                    <svg viewBox="0 0 24 24" role="presentation">
                      <g
                        strokeWidth="1.5"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="square"
                      >
                        <path
                          d="M10 17H4c-1.7 0-3 1.3-3 3v3h12v-3c0-1.7-1.3-3-3-3zM7 14c-1.7 0-3-1.3-3-3v-1c0-1.7 1.3-3 3-3s3 1.3 3 3v1c0 1.7-1.3 3-3 3z"
                          stroke="#1e2d7d"
                        ></path>
                        <path stroke="#00badb" d="M13 1v10l4-3h6V1z"></path>
                      </g>
                    </svg>
                  </div>
                  <div className="text-with-icons_content-wrapper">
                    <p className="text-with-icons_title text--strong">
                      We are available 24/7
                    </p>
                    <div className="text-with-icons_content rte">
                      <p>Contact us by chat, mail, phone</p>
                    </div>
                  </div>
                </div>
                <div className="text-with-icons_item" data-block-index="3">
                  <div className="text-with-icons_icon-wrapper">
                    <svg viewBox="0 0 24 24" role="presentation">
                      <g
                        strokeWidth="1.5"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="square"
                      >
                        <path
                          d="M1 5h22M1 9h22M9 17H3c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2h18c1.105 0 2 .895 2 2v10M5 13h5"
                          stroke="#1e2d7d"
                        ></path>
                        <path
                          stroke="#00badb"
                          d="M13 16h8v7h-8zM15 16v-2c0-1.1.9-2 2-2s2 .9 2 2v2M17 19v1"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <div className="text-with-icons_content-wrapper">
                    <p className="text-with-icons_title text--strong">
                      100% Secure payments
                    </p>
                    <div className="text-with-icons_content rte">
                      <p>Visa, Mastercard, Amex, PayPal</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="shopify-section-footer" className="shopify-section">
          <footer
            className="footer"
            data-section-id="footer"
            data-section-type="footer"
            role="contentinfo"
          >
            <div className="container">
              <div className="footer_wrapper">
                <div className="footer_block-list">
                  <div className="footer_block-item footer_block-item--text">
                    <button
                      className="footer_title heading h6"
                      disabled
                      aria-expanded="false"
                      aria-controls="block-footer-0"
                      data-action="toggle-collapsible"
                    >
                      <span>About the store</span>
                      <span className="plus-button"></span>
                    </button>

                    <div id="block-footer-0" className="footer_collapsible">
                      <div className="footer_collapsible-content">
                        <div className="rte">
                          <p>
                            Our mission statement is to provide&nbsp;the
                            absolute best customer experience&nbsp;available in
                            the Audio/Video industry without exception.
                          </p>
                          <p></p>
                          <p>
                            <em>
                              A big thank you to&nbsp; HiDEF Lifestyle &nbsp;for
                              allowing us to use their&nbsp;products in this
                              demo store.
                            </em>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer_block-item footer_block-item--links">
                    <button
                      className="footer_title heading h6"
                      disabled
                      aria-expanded="false"
                      aria-controls="block-footer-1"
                      data-action="toggle-collapsible"
                    >
                      <span>Popular Collections</span>
                      <span className="plus-button"></span>
                    </button>

                    <div id="block-footer-1" className="footer_collapsible">
                      <div className="footer_collapsible-content">
                        <ul className="footer_linklist list--unstyled">
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              Speakers
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              Audio Accessories
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              Headphones
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              Home Cinema
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              Sound Bars
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              LED TVs
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="footer_block-item footer_block-item--links">
                    <button
                      className="footer_title heading h6"
                      disabled
                      aria-expanded="false"
                      aria-controls="block-1559431882706"
                      data-action="toggle-collapsible"
                    >
                      <span>Information</span>
                      <span className="plus-button"></span>
                    </button>

                    <div
                      id="block-1559431882706"
                      className="footer_collapsible"
                    >
                      <div className="footer_collapsible-content">
                        <ul className="footer_linklist list--unstyled">
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              About us
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              Brands we distribute
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              Meet the team
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              Contact information
                            </a>{" "}
                          </li>
                          <li>
                            {" "}
                            <a href="/" className="footer_link-item link">
                              {" "}
                              FAQ
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="footer_block-item footer_block-item--newsletter">
                    <p className="footer_title heading h6">Our Newsletter</p>

                    <div className="footer_newsletter-wrapper">
                      <div className="footer_newsletter-text rte">
                        <p>
                          Subscribe to get notified about product launches,
                          special offers and news.
                        </p>
                      </div>
                      <form
                        method="post"
                        action=""
                        className="footer_newsletter-form"
                      >
                        <input
                          type="hidden"
                          name="form_type"
                          value="customer"
                        />
                        <input type="hidden" name="utf8" value="✓" />
                        <input type="hidden" />

                        <div className="form_input-wrapper form_input-wrapper--labelled">
                          <input
                            type="email"
                            className="form_field form_field--text"
                            required=""
                          />
                          <label className="form_floating-label">
                            Your email
                          </label>
                        </div>

                        <button
                          type="submit"
                          className="form_submit form_submit--tight button button--primary"
                        >
                          Subscribe
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
