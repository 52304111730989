import { GET_PROFILE } from "../actions/profileActions";

const profileReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_PROFILE:
			return payload;
		default:
			return state;
	}
};
export default profileReducer;
