import React, { Component } from "react";
import { toast } from "react-toastify";
// import { Link, Redirect } from "react-router-dom";
import auth from "../../services/authService";

var Joi = require("joi-browser");

const schema = Joi.object().keys({
  phone: Joi.string().required(),
  password: Joi.string().min(4).max(10).required(),
});
class Login extends Component {
  state = {
    phone: "",
    password: "",
    isactivate: false,
  };

  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
  };
  login = async () => {
    toast.configure();
    const { phone, password } = this.state;

    const validata = Joi.validate({ phone, password }, schema, function (
      err,
      value
    ) {
      if (!err) return null;
      const reterr = err.details[0].message;
      return reterr;
    });
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      setTimeout(async () => {
        await this.setState({
          phone: "",
          password: "",
        });
      }, 2000);
    } else {
      try {
        const obj = {
          phone: phone,
          password: password,
        };
        const data = await auth.login(obj);
        console.log(obj);

        if (data) {
          await this.setState({ token: data });
          setTimeout(() => {
            window.location = "/home";
            // this.props.history.push({
            // 	pathname: "/home",
            // 	state: { data: data },
            // });
          }, 2000);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setTimeout(async () => {
            await this.setState({ phone: "", password: "" });
          }, 2000);
        }
      }
    }
  };

  render() {
    // const { open } = this.state;

    return (
      <React.Fragment>
        <header className="popover__header">
          <h2 className="popover__title heading">Login to my account</h2>
          <p className="popover__legend">Enter your e-mail and password:</p>
        </header>
        <div className="form__input-wrapper form__input-wrapper--labelled">
          <input
            onChange={this.handleChange}
            value={this.state.phone}
            name="phone"
            className="form__field form__field--text"
          />
          <label className="form__floating-label">Phone/Email</label>
        </div>
        <div className="form__input-wrapper form__input-wrapper--labelled">
          <input
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
            name="password"
            className="form__field form__field--text"
          />
          <label className="form__floating-label">Password</label>
        </div>
        <div
          onClick={this.login}
          className="form__submit button button--primary button--full"
        >
          Login
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
