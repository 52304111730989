import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import Geolocation from "react-geolocation";
// import Geocode from "react-geocode";
// import get_categorys from "../../redux/actions/getCategorysActions";
// import { GoogleComponent } from "react-google-location";

// const API_KEY = "AIzaSyDaWJ96Z_w5kAiYcb4k_57FWITIIc1EeF4";
// Geocode.setApiKey("AIzaSyDaWJ96Z_w5kAiYcb4k_57FWITIIc1EeF4");
// Geocode.enableDebug();
// Geocode.fromLatLng("48.8583701", "2.2922926").then(
// 	(response) => {
// 		const address = response.results[0].formatted_address;
// 		console.log(address);
// 	},
// 	(error) => {
// 		console.error(error);
// 	},
// );

class Megamenu extends Component {
	state = {
		categories: [],
		subcategory: [],
		place: null,
		coordinates: null,
	};
	componentDidMount = async () => {
		// if (this.props.getcat && this.props.getcat.length <= 0) {
		// 	await get_categorys();
		// }
	};

	render() {
		const categories = this.props.getcategorys;

		return (
			<nav className="nav-bar">
				<div className="nav-bar__inner" style={{ marginTop: "-15px" }}>
					<div className="container">
						<ul className="nav-bar__linklist list--unstyled">
							{categories && categories.length > 0
								? categories.map((item, index) => {
										return (
											<li key={index} className="nav-bar__item">
												<Link className="nav-bar__link link">
													{item.category}
													<i className="fas fa-angle-down"> </i>
												</Link>
												<div className="mega-menu">
													<div className="container">
														<div className="mega-menu__inner mega-menu__inner--center mega-menu__inner--large">
															{item.subcategory.slice(0, 6).map((it, index) => {
																return (
																	<div
																		key={it.subcatname}
																		className="mega-menu__column 1/4--desk"
																	>
																		<Link
																			to={{
																				pathname: "/allproducts2",
																				it: it,
																				item: item,
																				type: "subcat",
																			}}
																			className="mega-menu__title heading"
																		>
																			{it.subcatname}
																		</Link>
																		<ul className="mega-menu__linklist">
																			{it.subsubcat
																				.slice(0, 5)
																				.map((i, index) => {
																					return (
																						<li
																							key={i.subsubcatname}
																							className="mega-menu__item "
																						>
																							<Link
																								to={{
																									pathname: "/allproducts",
																									p: i,
																									it: it,
																									item: categories.indexOf(
																										item,
																									),
																									ie: item,
																									type: "product",
																									type1: "cat",
																								}}
																								className="mega-menu__link link"
																							>
																								{i.subsubcatname}
																							</Link>
																						</li>
																					);
																				})}
																		</ul>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</li>
										);
								  })
								: null}
							<li className="nav-bar__item">
								<a href="/" className="nav-bar__link link blinking">
									Offers Zone
								</a>
							</li>
							<li className="nav-bar__item" style={{ marginRight: "68px" }}>
								<a href="/" className="nav-bar__link link">
									Deals
								</a>
							</li>
							<li className="nav-bar__item">
								{/* <Geolocation
                  render={({
                    fetchingPosition,
                    position: { coords: { latitude, longitude } = {} } = {},
                    error,
                    getCurrentPosition,
                  }) => (
                    <div>
                      <button onClick={getCurrentPosition}>Get Position</button>
                      {error && <div>{error.message}</div>}
                      <pre>
                        latitude: {latitude}
                        longitude: {longitude}
                      </pre>
                    </div>
                  )}
                /> */}
								{/* <GoogleComponent
                  className="locationBoxStyle"
                  apiKey={API_KEY}
                  language={"en"}
                  country={"country:in"}
                  coordinates={true}
                  placeholder={"Start typing location"}
                  locationBoxStyle={"custom-style"}
                  locationListStyle={"custom-style-list"}
                  onChange={(e) => {
                    this.setState({ place: e });
                  }}
                /> */}
								{/* <GoogleComponent
                  apiKey={API_KEY}
                  language={"en"}
                  country={"country:in"}
                  onChange={(e) => {
                    this.setState({ place: e });
                  }}
                /> */}
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		getcategorys: state.getcategorys,
	};
};
export default connect(mapStateToProps)(Megamenu);
