import store from "../index";
import cart from "../../services/cartService";

export var GET_ADDRESS = "GET_ADDRESS";

function get_address() {
	setTimeout(async () => {
		const authy = await cart.getaddress();
		store.dispatch({ type: GET_ADDRESS, payload: authy.data[0].address });
	}, 100);
}

export default get_address;
