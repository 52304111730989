import { ALL_PRODUCTS } from "../actions/allproductsActions";

const allproductsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ALL_PRODUCTS:
      return payload;
    default:
      return state;
  }
};
export default allproductsReducer;
