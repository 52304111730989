import React, { Component } from "react";
import { toast } from "react-toastify";
import users from "../../services/userservice";
import Header from "../common/header";

var Joi = require("joi-browser");

const schema = Joi.object().keys({
	password: Joi.string().min(8).max(10).required(),
	confirmpassword: Joi.any()
		.valid(Joi.ref("password"))
		.required()
		.options({ language: { any: { allowOnly: "must match password" } } }),
});

class ResetPassword extends Component {
	state = {
		password: "",
		confirmpassword: "",
		token: "",
	};
	componentDidMount = () => {
		this.setState({ token: this.props.match.params.id });
		window.history.replaceState(null, null, window.location.pathname);
	};
	handleChange = async (evt) => {
		await this.setState({ [evt.target.name]: evt.target.value });
	};
	create = async () => {
		toast.configure();
		const { password, confirmpassword } = this.state;
		const validata = Joi.validate(
			{ password, confirmpassword },
			schema,
			function (err, value) {
				if (!err) return null;
				const reterr = err.details[0].message;
				return reterr;
			},
		);
		if (!!validata) {
			toast.error(validata, {
				position: toast.POSITION.BOTTOM_LEFT,
			});

			setTimeout(async () => {
				await this.setState({
					password: "",
					confirmpassword: "",
				});
			}, 2000);
		} else {
			try {
				const obj = {
					token: this.props.match.params.id,
					password: password,
				};
				const data = await users.reset(obj);
				if (data.data.success) {
					toast.success(data.data.success, {
						position: toast.POSITION.BOTTOM_LEFT,
					});
					this.props.history.push("/home");
				}
			} catch (error) {}
		}
	};
	render() {
		return (
			<React.Fragment>
				<Header history={this.props.history} />
				{/* <div class="site-account" id="CustomerLoginForm">
					<div class="title-wrapper text-center mt-4">
						<ol className="breadcrumbs"></ol>
						<h1
							className="page-title"
							style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
						>
							Verification
						</h1>
					</div>
					<div class="form-group ">
						<label for="customer_login-email">
							Create Password<span class="required">*</span>
						</label>
						<input
							type="password"
							onChange={this.handleChange}
							value={this.state.password}
							name="password"
						/>
					</div>
					<div class="form-group ">
						<label for="customer_login-email">
							Confirm Password<span class="required">*</span>
						</label>
						<input
							type="password"
							onChange={this.handleChange}
							value={this.state.confirmpassword}
							name="confirmpassword"
						/>
					</div>
					<div class="form-group">
						<button onClick={this.create} class="btn btn-primary w100">
							create
						</button>
					</div>
				</div> */}
				<section data-section-id="login" data-section-type="login">
					<div class="container">
						<div class="form form--main" style={{ display: "block" }}>
							<header class="form__header">
								<h1 class="form__title heading h1">Reset Password</h1>
								<p class="form__legend">Create your Password:</p>
							</header>
							<div class="form__input-wrapper form__input-wrapper--labelled">
								<input
									type="password"
									class="form__field form__field--text"
									name="password"
									value={this.state.password}
									onChange={this.handleChange}
								/>
								<label for="password" class="form__floating-label">
									New Password
								</label>
							</div>

							<div class="form__input-wrapper form__input-wrapper--labelled">
								<input
									type="password"
									class="form__field form__field--text"
									name="confirmpassword"
									value={this.state.confirmpassword}
									onChange={this.handleChange}
								/>
								<label for="confirm password" class="form__floating-label">
									Confirm Password
								</label>
							</div>

							<button
								onClick={this.create}
								class="form__submit button button--primary button--full"
							>
								Create
							</button>
						</div>
					</div>
				</section>
			</React.Fragment>
		);
	}
}

export default ResetPassword;
