import { ADD_CART } from "../actions/cartactions";

const addcartReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case ADD_CART:
			return payload;
		default:
			return state;
	}
};
export default addcartReducer;
