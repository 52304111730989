import store from "../index";
import cart from "../../services/cartService";

export var CART_LIST = "CART_LIST";

function cart_list() {
  setTimeout(async () => {
    try {
      const authy = await cart.cartlist();
      store.dispatch({ type: CART_LIST, payload: authy.data });
    } catch (error) {}
  }, 100);
}

export default cart_list;
