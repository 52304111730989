import { GET_ITEMS } from "../actions/getitemsActions";

const getitemsReducer = (state = null, { type, payload }) => {
	switch (type) {
		case GET_ITEMS:
			return payload;
		default:
			return state;
	}
};
export default getitemsReducer;
