import React, { Component } from "react";
// import auth from "../../services/authService";
// import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Allcats extends Component {
	state = {
		allcats: {},
		catcollapse: false,
		catname: "",
		subcatname: "",
		subcatcollapse: false,
	};

	togglecatcollapse = async (cat) => {
		await this.setState({ catname: cat.category });
		await this.setState({ catcollapse: !this.state.catcollapse });
	};
	togglesubcatcollapse = async (sub) => {
		await this.setState({ subcatname: sub.subcatname });
		await this.setState({ subcatcollapse: !this.state.subcatcollapse });
	};
	render() {
		const allcats = this.props.getcategorys;

		return (
			<React.Fragment>
				<div class="card">
					<div class="card_section card_section--tight">
						<p class="card_title--small heading">All Categories</p>
						{allcats && allcats.length
							? allcats.map((cat, index) => {
									return (
										<ul
											className="pt-0 pb-0"
											style={{
												color: "#1e2d7d",
												cursor: "pointer",
											}}
										>
											{/* <Collapsible trigger={cat.category}> */}
											<div
												className="pt-0 pb-0"
												onClick={() => this.togglecatcollapse(cat)}
												style={{
													display: "flex",
												}}
											>
												<p style={{ width: "200px" }}>{cat.category}</p>
												<i className="fas fa-angle-down mt-1"> </i>
											</div>
											{this.state.catcollapse &&
											this.state.catname === cat.category
												? cat.subcategory.length > 0
													? cat.subcategory.map((sub, id) => {
															return (
																<li
																	className="pt-0 pb-0"
																	style={{
																		color: "#1e2d7d",
																		opacity: 1,
																		transition: "height 400ms linear 0s",
																		display: "flex",
																		cursor: "pointer",
																		marginBottom: "-15px",
																	}}
																>
																	<div
																		className="collection_filter-collapsible offset-1"
																		aria-hidden="false"
																		style={{
																			height: "auto",
																		}}
																	>
																		{/* <Collapsible
																							trigger={sub.subcatname}
																						> */}
																		<div
																			onClick={() =>
																				this.togglesubcatcollapse(sub)
																			}
																			style={{
																				display: "flex",
																			}}
																		>
																			<p
																				style={{
																					width: "200px",
																					marginTop: "10px",
																				}}
																			>
																				{sub.subcatname}
																			</p>
																			<i className="fas fa-angle-down mt-1">
																				{" "}
																			</i>
																		</div>
																		<ul
																			className="collection__filter-linklist"
																			style={{ color: "#1e2d7d" }}
																		>
																			{this.state.subcatcollapse &&
																			this.state.subcatname === sub.subcatname
																				? sub.subsubcat.length > 0
																					? sub.subsubcat.map((sscat, id) => {
																							return (
																								<li>
																									<button
																										className="collection_filter-link link link--primary is-active"
																										data-action="toggle-collapsible"
																										aria-controls="filter-0"
																										aria-expanded="true"
																									>
																										{/* <i className="fas fa-angle-down"> </i>{" "} */}
																									</button>
																									<div
																										id="filter-0"
																										className="collection_filter-collapsible offset-1"
																										aria-hidden="false"
																										style={{
																											height: "auto",
																											marginBottom: "5px",
																										}}
																									>
																										<Link
																											to={{
																												pathname:
																													"/allproducts",
																												p: sscat,
																												it: sub,
																												item: allcats.indexOf(
																													cat,
																												),
																												ie: cat,

																												type: "product",
																											}}
																										>
																											{sscat.subsubcatname}
																										</Link>
																									</div>
																								</li>
																							);
																					  })
																					: null
																				: null}
																		</ul>
																		{/* </Collapsible> */}
																	</div>
																</li>
															);
													  })
													: null
												: null}{" "}
											{/* </Collapsible> */}
										</ul>
									);
							  })
							: null}
					</div>
				</div>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		getcategorys: state.getcategorys,
	};
};
export default connect(mapStateToProps)(Allcats);

// export default Allcats;
