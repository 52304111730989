import React, { Component } from "react";
import Carousel from "nuka-carousel";
// import RecentProducts from "../products/recentproducts";

class HomeCarousel extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="slider_car">
          <Carousel
            autoplay={true}
            autoplayInterval={5000}
            slidesToScroll={"auto"}
            enableKeyboardControls={true}
            autoGenerateStyleTag={true}
            wrapAround={true}
            renderBottomCenterControls={false}
            renderCenterLeftControls={({ previousSlide }) => (
              <button className="chbutleft" onClick={previousSlide}>
                <i className="fas fa-chevron-left"></i>
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className="chbutright" onClick={nextSlide}>
                <i className="fas fa-chevron-right" />
              </button>
            )}
          >
            <img
              alt="carousel"
              // src={require("../../images/2.jpg")}
              src={require("../../assets/images/04.jpg")}
            />

            <img
              alt="carousel"
              src={require("../../assets/images/02.jpg")}
              // "https://i.pinimg.com/originals/49/16/ba/4916baf0575b121dac9bb85e23f80c21.jpg"
            />
            <img
              alt="carousel"
              src={require("../../assets/images/03.jpg")}
              // "https://i.pinimg.com/originals/49/16/ba/4916baf0575b121dac9bb85e23f80c21.jpg"
            />
            <img
              alt="carousel"
              src={require("../../assets/images/01.jpg")}
              // "https://i.pinimg.com/originals/49/16/ba/4916baf0575b121dac9bb85e23f80c21.jpg"
            />
          </Carousel>
        </div>
        {/* <RecentProducts history={this.props.history} /> */}
      </React.Fragment>
    );
  }
}

export default HomeCarousel;
