import React, { Component } from "react";
import { toast } from "react-toastify";
import users from "../../services/userservice";
var Joi = require("joi-browser");

const schema = Joi.object().keys({
  name: Joi.string().min(4).max(20).required(),
  phone: Joi.string().min(10).max(10).required(),
  email: Joi.string().email().required(),
  password: Joi.string().min(4).max(10).required(),
});
class Register extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    password: "",
    isactivate: false,
    token: "",
  };
  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
  };
  register = async () => {
    toast.configure();
    const { name, phone, email, password } = this.state;
    const validata = Joi.validate(
      { name, phone, email, password },
      schema,
      function (err, value) {
        if (!err) return null;
        const reterr = err.details[0].message;
        return reterr;
      }
    );
    if (!!validata) {
      toast.error(validata, {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      setTimeout(async () => {
        await this.setState({
          name: "",
          phone: "",
          email: "",
          password: "",
        });
      }, 2000);
    } else {
      try {
        const obj = {
          name: name,
          email: email,
          phone: phone,
          password: password,
        };
        const data = await users.register(obj);
        console.log(data);

        if (data.data.success) {
          //await this.setState({ token: data.data.token });
          setTimeout(() => {
            this.props.history.push({
              pathname: "/home",
              state: { token: data.data.token },
            });
            toast.success("User SuccessFully Registered", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }, 2000);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data, {
            position: toast.POSITION.BOTTOM_LEFT,
          });

          setTimeout(async () => {
            await this.setState({
              name: "",
              phone: "",
              email: "",
              password: "",
            });
          }, 2000);
        }
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <header className="popover__header">
          <h2 className="popover__title heading">Create my account</h2>
          <p className="popover__legend">
            Please fill in the information below:
          </p>
        </header>

        <div className="form__input-wrapper form__input-wrapper--labelled">
          <input
            value={this.state.name}
            type="text"
            className="form__field form__field--text"
            name="name"
            onChange={this.handleChange}
          />
          <label className="form__floating-label">Name</label>
        </div>

        <div className="form__input-wrapper form__input-wrapper--labelled">
          <input
            type="number"
            value={this.state.phone}
            name="phone"
            className="form__field form__field--text"
            onChange={this.handleChange}
          />
          <label className="form__floating-label">Phone</label>
        </div>

        <div className="form__input-wrapper form__input-wrapper--labelled">
          <input
            type="email"
            value={this.state.email}
            name="email"
            className="form__field form__field--text"
            onChange={this.handleChange}
          />
          <label className="form__floating-label">Email</label>
        </div>
        <div className="form__input-wrapper form__input-wrapper--labelled">
          <input
            name="password"
            value={this.state.password}
            type="password"
            className="form__field form__field--text"
            onChange={this.handleChange}
          />
          <label className="form__floating-label">Password</label>
        </div>

        <button
          onClick={this.register}
          className="form__submit button button--primary button--full"
        >
          Create my account
        </button>

        {/* <div class="site-account" id="CustomerLoginForm">
					<div>
						<div class="title-wrapper text-center mt-4">
							<ol className="breadcrumbs"></ol>
							<h1
								className="page-title"
								style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
							>
								Register
							</h1>
						</div>
						<div class="form-group ">
							<label for="customer_login-email">
								Name<span class="required">*</span>
							</label>
							<input
								onChange={this.handleChange}
								value={this.state.name}
								type="text"
								name="name"
							/>
						</div>
						<div class="form-group ">
							<label for="customer_login-email">
								Mobile<span class="required">*</span>
							</label>
							<input
								onChange={this.handleChange}
								value={this.state.phone}
								type="number"
								name="phone"
							/>
						</div>
						<div class="form-group ">
							<label for="customer_login-email">
								Email address<span class="required">*</span>
							</label>
							<input
								onChange={this.handleChange}
								value={this.state.email}
								type="email"
								name="email"
							/>
						</div>
						<div class="form-group ">
							<label for="customer_login-email">
								Password<span class="required">*</span>
							</label>
							<input
								onChange={this.handleChange}
								value={this.state.password}
								type="password"
								name="password"
							/>
						</div>

						<div class="form-group">
							<button onClick={this.register} class="btn btn-primary w100">
								Register
							</button>
						</div>
						<div class="form-divider">
							<span>Or</span>
						</div>
						<div class="form-group">
							<Link to="/login" class="btn w100">
								Login
							</Link>
						</div>
					</div>
				</div> */}
      </React.Fragment>
    );
  }
}

export default Register;
