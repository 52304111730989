import React, { Component } from "react";
import HomeCarousel from "./homecarousel";
import RecentProducts from "../products/recentproducts";
import Topproducts from "../products/topproducts";
import Header from "../common/header";
import { connect } from "react-redux";
import Dealzone from "./dealzone";

class Home extends Component {
  state = {};
  componentDidMount = async () => {};
  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <HomeCarousel />
        <RecentProducts history={this.props.history} />
        {this.props.getproducts ? (
          <div>
            <Dealzone />
            <Topproducts history={this.props.history} />
          </div>
        ) : (
          <div className="offset-md-4">
            <img src={require("../../assets/images/loader.gif")} alt="loader" />
          </div>
        )}

        {/* <Storeinfo /> */}
        {/* <Contactus /> */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getcategorys: state.getcategorys,
    cartlist: state.cartlist,
    getproducts: state.getproducts,
  };
};
export default connect(mapStateToProps)(Home);
