import React, { Component } from "react";
import Carousel from "nuka-carousel";
import OwlCarousel from "react-owl-carousel-safe";
import { connect } from "react-redux";

class Dealzone extends Component {
  state = {};
  render() {
    const pr = this.props.getproducts;
    return (
      <React.Fragment>
        <div className="shopify-section">
          <section
            className="section"
            data-section-type="featured-collection"
            data-section-settings="{ }"
          >
            <div className="container">
              <header className="section_header">
                <div className="section_header-stack">
                  <h2 className="section_title heading h3">Deal zone</h2>
                </div>
                <a
                  href="collections/sales.html"
                  className="section_action-link link"
                >
                  View all sales
                  <svg
                    className="icon icon--tail-right"
                    viewBox="0 0 24 24"
                    role="presentation"
                  >
                    <path
                      fill="currentColor"
                      d="M22.707 11.293L15 3.586 13.586 5l6 6H2c-.553 0-1 .448-1 1s.447 1 1 1h17.586l-6 6L15 20.414l7.707-7.707c.391-.391.391-1.023 0-1.414z"
                    ></path>
                  </svg>
                </a>
              </header>
            </div>

            <div className="container ">
              <div className="scroller">
                <div className="scroller_inner">
                  <OwlCarousel
                    autoPlay={true}
                    key={`carousel_${
                      this.props.getproducts ? this.props.getproducts : 0
                    }`}
                    nav={false}
                    responsiveClass={true}
                    items={5}
                    margin={0}
                    autoWidth={true}
                    loop={true}
                  >
                    {pr &&
                      pr.slice(0, 10).map((p, index) => {
                        return (
                          <div class="product-list product-list--vertical product-list--scrollable ">
                            <div
                              key={p._id}
                              class="product-item product-item--vertical"
                              style={{ width: "100%" }}
                            >
                              <div class="product-item_label-list">
                                <span class="product-label product-label--on-sale">
                                  Save
                                  <span>₹800</span>
                                </span>
                              </div>
                              <a href="" class="product-item_image-wrapper ">
                                <div class="aspect-ratio aspect-ratio--square">
                                  <img
                                    class="product-item_primary-image lazyload "
                                    src={p.image}
                                    // src={require("../../assets/images/products/pro1.jpg")}
                                  />
                                </div>
                              </a>

                              <div class="product-item_info">
                                <div class="product-item_info-inner">
                                  <a class="product-item_vendor link" href="">
                                    Sony
                                  </a>
                                  <a
                                    href=""
                                    class="product-item_title text--strong link"
                                  >
                                    Sony BRAVIA 4K HDR Ultra HD TV
                                  </a>
                                  <div class="product-item_price-list price-list">
                                    <span class="price price--highlight">
                                      ₹1,398
                                    </span>
                                    <span class="price price--compare">
                                      ₹2,198
                                    </span>
                                  </div>
                                  <span class="product-item_inventory inventory inventory--high">
                                    In stock, 80 units
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getproducts: state.getproducts,
    getcategorys: state.getcategorys,
    getitems: state.getitems,
  };
};
export default connect(mapStateToProps)(Dealzone);

// export default Dealzone;
{
  /* <img
											alt="carousel"
											// src={require("../../images/2.jpg")}
											src={require("../../assets/images/img.png")}
											/> */
}
