import store from "../index";
import auth from "../../services/authService";

export var GET_PRODUCTS = "GET_PRODUCTS";

function get_products() {
  setTimeout(async () => {
    try {
      const authy = await auth.getlist();
      store.dispatch({ type: GET_PRODUCTS, payload: authy.data });
    } catch (error) {}
  }, 100);
}

export default get_products;
