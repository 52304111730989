import React, { Component } from "react";
import logo from "../../assets/images/localcart.png";
import { connect } from "react-redux";
import Login from "../login/index";
import {
	MDBTabPane,
	MDBTabContent,
	MDBNav,
	MDBNavItem,
	MDBNavLink,
} from "mdbreact";
import Register from "../register";
import auth from "../../services/authService";
import Megamenu from "./megamenu";
import update_auth from "../../redux/actions/authActions";
import HeaderSearch from "./headersearch";
import RecoverPassword from "../resetpassword/verification";
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import cart_list from "../../redux/actions/cartlistActions";
import get_products from "../../redux/actions/getproductsActions";
import { Link } from "react-router-dom";

import get_categorys from "../../redux/actions/getCategorysActions";
import get_items from "../../redux/actions/getitemsActions";

import Sidebar from "./sidebar";

class Header extends Component {
	state = {
		isOpen: false,
		activeItem: "1",
		logindropdownopen: false,
		logintoggle: false,
		logoutdropdownopen: false,
		cartdropdown: false,
		cartitems: [],
		data: null,
		cartloader: false,
		sideopen: false,
	};

	carts = [];
	cartloader = true;

	componentDidMount = async () => {
		if (!this.props.getcategorys) {
			await get_categorys();
		}
		if (!this.props.cartlist) {
			await cart_list();
		}
		if (!this.props.getproducts) {
			await get_products();
		}
		if (!this.props.getitems) {
			await get_items();
		}
		await update_auth();
	};

	productpage = (item) => {
		this.props.history.push({
			pathname: "/productdetails",
			state: { data: item },
		});
	};
	allproducts = () => {
		this.props.history.push({ pathname: "/allproducts3", type: "allprod" });
	};
	onOpenModal = () => {
		this.setState({ open: true });
	};
	onCloseModal = () => {
		this.setState({ open: false });
	};
	toggle = (tab) => (e) => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	};
	// toggle1 = () => {
	//   this.setState({ isOpen: !this.state.isOpen });
	// };
	cartpage = () => {
		this.props.history.push({
			pathname: "/viewcart",
			state: { data: this.props.cartlist },
		});
	};
	checkoutpage = () => {
		this.props.history.push({
			pathname: "/checkout",
			state: { data: this.state.cartitems },
		});
	};
	toggleCollapse = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};
	handlescroll = () => {
		window.onscroll = () => {
			const nav = document.querySelector("#navbar");
			if (this.scrollY <= 10) nav.classNameName = "";
			else nav.classNameName = "scroll";
		};
	};
	viewcart = (prd) => {
		this.props.history.push({
			pathname: "/viewcart",
			state: { data: prd },
		});
	};
	dropdowndisplay = async () => {
		this.setState({
			logindropdownopen: !this.state.logindropdownopen,
		});
	};
	logintoggle = () => {
		this.setState({
			logindropdownopen: !this.state.logindropdownopen,
		});
	};
	cartdropdowndisplay = async () => {
		this.setState({
			cartdropdown: !this.state.cartdropdown,
		});
	};
	logoutdropdowndisplay = async () => {
		this.setState({
			logoutdropdownopen: !this.state.logoutdropdownopen,
		});
	};
	logout = async () => {
		auth.logout();
		this.props.history.push("/home");
	};
	handlechangedata(data) {}
	sidebar = async () => {
		await this.setState({ sideopen: !this.state.sideopen });
	};

	// removeitem = (item) => {
	//   item.qty = item.qty - 1;
	// };
	render() {
		let prod = [];
		let prd = [];
		const cart = this.props.cartlist;
		if (
			cart &&
			cart.length > 0 &&
			this.props.getproducts &&
			this.props.getproducts.length > 0
		) {
			const prod = cart.map((item) => {
				const ie = this.props.getproducts.filter(
					(i) => i.itemid === item.itemid,
				);
				const fi = ie.map((element) => {
					return {
						image: element.image,
						dealprice: element.dealprice,
						mrp: element.mrp,
						title: element.title,
						description: element.description,
						itemid: element.itemid,
						cartid: item.cartid,
						qty: item.qty,
					};
				});
				const ief = fi.filter((i) => i.itemid === item.itemid);
				return ief;
				//return fi;
			});

			if (prod && prod.length > 0) {
				prod.map((item) => {
					prd.push(item[0]);
				});
			}
		}

		return (
			<React.Fragment>
				<div id="shopify-section-header" className="shopify-section">
					<section>
						<header className="header header--inline header--search-expanded">
							<div className="container">
								<div className="header__inner ">
									{/* <nav className="header__mobile-nav hidden-lap-and-up"></nav> */}

									<button
										onClick={() => this.sidebar()}
										class="navbar-toggler"
										type="button"
									>
										{!this.state.sideopen ? (
											<span class="navbar-toggler-icon d-md-none">
												<i class="fa fa-bars" aria-hidden="true"></i>
											</span>
										) : (
											<span
												onClick={() => this.sidebar()}
												className=" d-md-none"
											>
												<i class="fa fa-times " aria-hidden="true"></i>
											</span>
										)}
									</button>

									<Link to="/" className="header__logo-link">
										<img className="header__logo-image" src={logo} alt="img" />
									</Link>

									<HeaderSearch history={this.props.history} />

									<div className="header__action-list">
										{!auth.getCurrentUser() ? (
											<div>
												<UncontrolledDropdown
													style={{
														backgroundColor: "transparent",
														marginLeft: "auto",
													}}
													className="header__action-item header__action-item--account cursor-pointer"
												>
													<DropdownToggle className="header__action-item header__action-item--cart ">
														<span className="header__action-item-title">
															Login / Signup
														</span>
														<div class="avatar-m d-flex ">
															<i className="fa fa-user-circle signup mr-4 "></i>
														</div>

														<div className="header__action-item-link header__account-icon icon-state">
															<div className="header__action-item-link hidden-pocket hidden-lap">
																My account
																<i
																	style={{ fontSize: "12px" }}
																	className="fa fa-chevron-down"
																></i>
															</div>
														</div>
														{/* <span>Login/signUp</span> */}
													</DropdownToggle>

													<div class="avatar-m">
														{" "}
														<i
															className="fas fa-shopping-cart ml-2 signup mt-3 mr-4"
															onClick={() => this.allproducts()}
														></i>{" "}
													</div>
													<span className="header__cart-count signup text-white mt-3 mr-4">
														0
													</span>

													{/* <div class="avatar-m">
                            {" "}
                            <i className="fas fa-shopping-cart signup mt-3 mr-4"></i>{" "}
                          </div> */}

													{/* <DropdownToggle>
                            <div className="header__cart-icon icon-state">
                              <i className="fas fa-shopping-cart text-white"></i>
                              <span className="header__cart-count text-white">
                                0
                              </span>
                            </div>
                          </DropdownToggle> */}
													<DropdownMenu>
														<DropdownItem toggle={false}>
															<div className="popover__panel-list">
																<div className="popover__inner">
																	<MDBTabContent
																		activeItem={this.state.activeItem}
																	>
																		<MDBTabPane tabId="1" role="tabpanel">
																			<Login />
																		</MDBTabPane>
																		<MDBTabPane tabId="2" role="tabpanel">
																			<Register history={this.props.history} />
																		</MDBTabPane>
																		<MDBTabPane tabId="3" role="tabpanel">
																			<RecoverPassword
																				history={this.props.history}
																			/>
																		</MDBTabPane>
																	</MDBTabContent>
																	<div className="popover__secondary-action ">
																		{this.state.activeItem === "1" ? (
																			<MDBNav className="nav-tabs">
																				<MDBNavItem className="m-auto">
																					<MDBNavLink
																						to="#"
																						active={
																							this.state.activeItem === "1"
																						}
																						onClick={this.toggle("2")}
																						role="tab"
																					>
																						<p className="text-center">
																							New customer?{" "}
																							<span className="link link--accented">
																								Create Account
																							</span>
																						</p>
																					</MDBNavLink>
																				</MDBNavItem>
																			</MDBNav>
																		) : (
																			<MDBNav className="nav-tabs">
																				<MDBNavItem className="m-auto">
																					<MDBNavLink
																						to="#"
																						active={
																							this.state.activeItem === "2"
																						}
																						onClick={this.toggle("1")}
																						role="tab"
																					>
																						<p className="text-center">
																							Already Have an Account?{" "}
																							<span className="link link--accented">
																								Login Here
																							</span>
																						</p>
																					</MDBNavLink>
																				</MDBNavItem>
																			</MDBNav>
																		)}
																		{this.state.activeItem === "1" ? (
																			<MDBNav className="nav-tabs">
																				<MDBNavItem className="m-auto">
																					<MDBNavLink
																						to="#"
																						active={
																							this.state.activeItem === "3"
																						}
																						onClick={this.toggle("3")}
																						role="tab"
																					>
																						<p className="text-center">
																							Lost password? &nbsp;
																							<span className="link link--accented">
																								Recover Password
																							</span>
																						</p>
																					</MDBNavLink>
																				</MDBNavItem>
																			</MDBNav>
																		) : null}
																	</div>
																</div>
															</div>
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
										) : (
											<UncontrolledDropdown
											// style={{ backgroundColor: "transparent" }}
											// className="signup"
											>
												<DropdownToggle className="header__action-item ">
													<span className="header__action-item-title">
														{this.props.auth.name}
														<i
															style={{ fontSize: "10px" }}
															className="fa fa-chevron-down ml-1"
														></i>
													</span>

													<div className="header__action-item-link  icon-state">
														<div class="avatar-m d-flex ">
															<i className="fa fa-user-circle signup ml-1 mr-2"></i>
														</div>
													</div>
												</DropdownToggle>
												<div className="avatar-m icon-state pt-1">
													<i
														className="fas fa-shopping-cart signup ml-2"
														onClick={() => this.viewcart(prd)}
													>
														<span className="header__cart-count text-white ">
															{this.props.cartlist
																? this.props.cartlist.length
																: 0}
														</span>
													</i>
												</div>

												<DropdownMenu className="cust">
													<DropdownItem className="signup">
														{this.props.auth.name}
													</DropdownItem>
													<DropdownItem>My Orders</DropdownItem>
													<Link to="/checkout">
														{" "}
														<DropdownItem>My Addresses</DropdownItem>
													</Link>
													<DropdownItem onClick={this.logout}>
														Logout
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										)}

										<div className="">
											{!auth.getCurrentUser() ||
											(auth.getCurrentUser && cart && !cart.length) ? (
												<UncontrolledDropdown className="header__action-item header__action-item--cart">
													<DropdownToggle
														className="header__action-item-title   transparent"
														color="none"
													>
														<div className="header__cart-icon icon-state">
															<i className="fas fa-shopping-cart text-white"></i>
															<span className="header__cart-count text-white">
																0
															</span>
														</div>
														<span className="hidden-pocket hidden-lap text-white">
															Cart
														</span>
													</DropdownToggle>

													<DropdownMenu className="custdrop">
														<DropdownItem toggle={false}>
															<div className="popover__panel-list">
																<div className="popover__inner">
																	<div className="mini-cart__content mini-cart__content--empty">
																		<p className="alert alert--tight alert--center text--strong">
																			Spend{" "}
																			<span data-money-convertible>₹80</span>{" "}
																			more and get free shipping!
																		</p>
																		<div className="mini-cart__empty-state">
																			<svg
																				width="81"
																				height="70"
																				viewBox="0 0 81 70"
																			>
																				<g
																					transform="translate(0 2)"
																					strokeWidth="4"
																					stroke="#1e2d7d"
																					fill="none"
																					fillRule="evenodd"
																				>
																					<circle
																						strokeLinecap="square"
																						cx="34"
																						cy="60"
																						r="6"
																					></circle>
																					<circle
																						strokeLinecap="square"
																						cx="67"
																						cy="60"
																						r="6"
																					></circle>
																					<path d="M22.9360352 15h54.8070373l-4.3391876 30H30.3387146L19.6676025 0H.99560547"></path>
																				</g>
																			</svg>
																			<p className="heading h4">
																				Your cart is empty
																			</p>
																		</div>

																		<p
																			onClick={() => this.allproducts()}
																			className="button button--primary button--full"
																		>
																			Shop our products
																		</p>
																	</div>
																</div>
															</div>
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											) : (
												<div
													onClick={() => this.viewcart(prd)}
													className="header__action-item-title 
													 transparent ml-4 cursor-pointer"
													color="none"
												>
													<div className="header__cart-icon icon-state">
														<i className="fas fa-shopping-cart text-white"></i>

														<span className="header__cart-count text-white">
															{prd ? prd.length : 0}
														</span>
													</div>
													<span className=" text-white">Cart</span>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</header>
						<Megamenu history={this.props.history} />
					</section>
				</div>
				{this.state.sideopen ? (
					<div
						style={{
							position: "absolute",
							height: "100%",
							width: "90%",
							top: "55px",
							zIndex: 9999,
						}}
					>
						<Sidebar history={this.props.history} />
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		cartlist: state.cartlist,
		getproducts: state.getproducts,
		getcategorys: state.getcategorys,
		getitems: state.getitems,
	};
};

export default connect(mapStateToProps)(Header);
