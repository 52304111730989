import React, { Component } from "react";
import auth from "../../services/authService";
import { connect } from "react-redux";
import Joi from "joi-browser";
import user from "../../services/userservice";
import { withRouter } from "react-router";

class HeaderSearch extends Component {
	state = {
		allcats: [],
		allproducts: [],
		data: {},
		prod: [],
		searchtext: "",
		catselected: "",
		inprod: [],
	};
	schema = {
		search: Joi.string(),
		category: Joi.string(),
	};

	componentDidMount = async () => {
		//   const products = await auth.getlist();
		//  // const categs = await auth.getcategories();
		//   this.setState({ allcats: categs.data, allproducts: products.data.success });
	};
	validate = () => {
		const options = { abortEarly: false };
		const { error } = Joi.validate(this.state.data, this.schema, options);
		if (!error) return null;
		const errors = {};
		for (let item of error.details) errors[item.path[0]] = item.message;
		return errors;
	};
	validateProperty = ({ name, value }) => {
		const obj = { [name]: value };
		const schema = { [name]: this.schema[name] };
		const { error } = Joi.validate(obj, schema);
		return error ? error.details[0].message : null;
	};
	handleSubmit = async (e) => {
		const errors = this.validate();
		this.setState({ errors: errors || {} });
		if (errors) return;
		this.doSubmit();
		e.preventDefault();
	};
	handlecategory = async (e) => {
		await this.setState({ catselected: e.target.value });
		// const catselect = this.state.prod.filter((i) => {
		// return i.categoryid === this.state.catselected;
		// });
		// if (this.state.catselected) {
		// await this.setState({ prod: catselect });
		// } else {
		// await this.setState({ prod: this.state.allproducts, text: "" });
		// }
	};
	handleChange = async (e) => {
		await this.setState({ searchtext: e.target.value });
		// const datat = this.state.allproducts.filter((d) =>
		// d.title.toLowerCase().includes(this.state.text.toLowerCase()),
		// );
		// this.setState({ prod: datat, inprod: datat });

		// const { allproducts } = this.state;
		// const errors = { ...this.state.errors };
		// const data = { ...this.state.data };
		// data[input.name] = input.value;
		// await this.setState({ data, errors });
		// if (input.value.length <= 0) {
		// this.setState({ prod: this.state.allproducts });
		// } else if (input.name === "category") {
		// if (input.value === "All Categories..") {
		// await this.setState({ prod: this.state.allproducts });
		// } else {
		// const datat = allproducts.filter((d) => d.categoryid === data.category);
		// this.setState({ prod: datat });
		// }
		// } else {
		// this.doSearch();
		// }
	};
	doSearch = () => {
		const { data, allproducts, prod } = this.state;
		if (allproducts && allproducts.length) {
			const datat = allproducts.filter((d) =>
				d.title.toLowerCase().includes(data.search.toLowerCase()),
			);
			this.setState({ prod: datat });
		}
	};
	doSubmit = () => {
		const { data } = this.state;
		const { allproducts, prod } = this.state;
		if (allproducts && allproducts.length) {
			const datat = allproducts.filter(
				(d) =>
					d.categoryid === data.category &&
					d.title.toLowerCase().includes(data.search.toLowerCase()),
			);
			this.setState({ prod: datat });
		}
	};

	searchsubmit = async () => {
		const { searchtext, catselected } = this.state;
		try {
			if (!catselected) {
				const obj = {
					search: searchtext,
				};
				const data = await user.search(obj);

				if (data.data) {
					setTimeout(() => {
						this.props.history.push({
							pathname: "/searchproducts",
							state: { data: data.data, type: "search" },
						});
						// this.props.history.push({
						// pathname: "/allproducts",
						// type: "search",
						// data: data.data.success,
						// });
					}, 2000);
				}
			} else {
				const obj = {
					search: searchtext,
					categoryid: catselected,
				};
				const data = await user.search(obj);

				if (data.data) {
					setTimeout(() => {
						this.props.history.push({
							pathname: "/searchproducts",
							state: { data: data.data, type: "search" },
						});
						// this.props.history.push({
						// pathname: "/allproducts",
						// type: "search",
						// data: data.data.success,
						// });
					}, 2000);
				}
			}
		} catch (error) {}
	};
	// passdata=()=>{
	// const data = this.state.prod
	// this.props.history.push({
	// pathname: "/header",
	// data:data
	// });
	// }
	render() {
		const categories = this.props.getcategorys;
		const prods = this.state.allproducts;
		return (
			<React.Fragment>
				<div className="header__search-bar-wrapper is-visible">
					<div className="search-bar__top-wrapper">
						<div className="search-bar__top">
							<div className="search-bar__input-wrapper">
								<input
									className="search-bar__input"
									type="text"
									name="search"
									placeholder="Search..."
									onChange={this.handleChange}
								/>
							</div>
							<div
								className="form-group col-xl-4 col-lg-4 select2-lg col-md-12 mb-0"
								style={{ borderLeft: "1px solid #dbe0e8" }}
							>
								<select
									name="category"
									id="category"
									className="search-bar__filter-label"
									style={{ border: "0px", background: "white" }}
									onChange={this.handlecategory}
								>
									<optgroup>
										<option>All Categories..</option>
										{categories && categories.length > 0
											? categories.map((cat) => {
													return (
														<option key={cat._id} value={cat._id}>
															{cat.category}
														</option>
													);
											  })
											: null}
									</optgroup>
								</select>
							</div>
							<button
								onClick={() => this.searchsubmit()}
								className="search-bar__submit"
							>
								<svg
									className="icon icon--search"
									viewBox="0 0 21 21"
									role="presentation"
								>
									<g
										strokeWidth="2"
										stroke="currentColor"
										fill="none"
										fillRule="evenodd"
									>
										<path d="M19 19l-5-5" strokeLinecap="square"></path>
										<circle cx="8.5" cy="8.5" r="7.5"></circle>
									</g>
								</svg>
							</button>
						</div>
					</div>
				</div>
				{this.state.prod.length > 0 ? (
					<div className="searchcontainer">
						{this.state.prod.map((item) => {
							return (
								<div className="searchscroll">
									<p>{item.title}</p>
								</div>
							);
						})}
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		cartlist: state.cartlist,
		getproducts: state.getproducts,
		getcategorys: state.getcategorys,
	};
};

export default connect(mapStateToProps)(HeaderSearch);
