import React, { Component } from "react";
import Header from "../common/header";
import { connect } from "react-redux";
import Allcats from "../common/allcats";
import { toast } from "react-toastify";
import cart_list from "../../redux/actions/cartlistActions";
import cart from "../../services/cartService";

class Catproducts extends Component {
  state = {
    getproducts: [],
    image: {},
    allcats: {},
    loader: false,
  };
  componentDidMount = async () => {
    // if (this.props.allproducts.length < 1) {
    //   this.setState({ loader: !this.state.loader });
    //   setTimeout(() => {
    //     this.setState({ loader: !this.state.loader });
    //   }, 3000);
    // }
  };

  addcart = async (item) => {
    console.log(item);
    toast.configure();
    const obj = {
      itemid: item.itemid,
      size: "20",
      qty: parseInt(this.state.quantity),
    };
    try {
      const data = await cart.addcart(obj);

      if (data.data.success) {
        await cart_list();
        toast.success(data.data.success, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Please Login to your account", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    }
  };

  productpage = (item) => {
    this.props.history.push({
      pathname: "/productdetails",
      state: { data: item },
    });
  };
  render() {
    const getproducts = this.props.allproducts;

    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div id="shopify-section-product-template" className="shopify-section">
          <div className="container container--flush">
            <div className="page_sub-header"></div>
            <div className="layout">
              <div className="layout_section layout_section--secondary hidden-pocket">
                <Allcats />
              </div>

              <div class="layout__section">
                <div
                  className="collection__image-wrapper collection__image-wrapper--medium"
                  style={{
                    backgroundImage:
                      "url(https://www.artisstore.com/media/responsivebannerslider/artiswebsitebanner3_1570619470.jpg)",
                  }}
                ></div>

                {/* <div className="card ">
                  <header className="card__header card__header--tight">
                    <div className="collection__header ">
                      <div className="collection__header-inner">
                        <div className="collection__meta">
                          <div className="collection__meta-inner">
                            <h1 className="collection__title heading h1"></h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="collection__toolbar ">
                    <div className="collection__toolbar-item collection__toolbar-item--count hidden-pocket">
                      <span className="collection__showing-count hidden-pocket hidden-lap">
                        Showing &nbsp;
                        {getproducts ? (
                          getproducts.length
                        ) : (
                          <Redirect to="/home" />
                        )}{" "}
                        products
                      </span>
                    </div>
                    <div className="collection__toolbar-item collection__toolbar-item--sort">
                      <div className="value-picker-wrapper">
                        <button
                          className="value-picker-button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-controls="sort-by-selector"
                          data-action="open-value-picker"
                        >                         
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
                {this.state.loader ? (
                  <div className="offset-3 mt-4">
                    <img
                      src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                      alt="loader"
                    />
                  </div>
                ) : (
                  <div className="product-list product-list--collection product-list--with-sidebar">
                    {!!getproducts ? (
                      getproducts.map((p, index) => {
                        return (
                          <div
                            key={index}
                            className="product-item product-item--vertical 1/4--desk"
                          >
                            <a href="" className="product-item__image-wrapper ">
                              <div className="aspect-ratio aspect-ratio--square">
                                <img
                                  onClick={() => this.productpage(p)}
                                  className="product-item__primary-image lazyload "
                                  src={p.image[0]}
                                />
                              </div>
                            </a>
                            <div className="product-item__info">
                              <div className="product-item__info-inner">
                                <a
                                  href=""
                                  className="product-item__title text--strong link"
                                >
                                  {" "}
                                  {p.title.slice(0, 21)}
                                </a>

                                <div className="product-item__price-list price-list mb-2">
                                  <span class="price price--highlight">
                                    ₹{p.mrp}
                                  </span>
                                  <span class="price price--compare">
                                    ₹{p.dealprice}
                                  </span>
                                </div>
                              </div>
                              <br />
                              <button
                                type="button"
                                className="product-item_action-button  button--small button--primary"
                              >
                                {" "}
                                Buy Now{" "}
                              </button>
                              <button
                                onClick={() => this.addcart(p)}
                                type="button"
                                className="product-item_action-button button button--small button--ternary hidden-phone"
                              >
                                {" "}
                                Add to Cart{" "}
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="offset-5">
                        <img
                          src={require("../../assets/images/loader.gif")}
                          alt="loader"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allproducts: state.getproducts,
    getproducts: state.getproducts,
    getcategorys: state.getcategorys,
    getitems: state.getitems,
  };
};
export default connect(mapStateToProps)(Catproducts);
