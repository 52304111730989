import jwtDecode from "jwt-decode";
import http from "./httpservice";

const apiEndpoint = "/users";
const tokenKey = "token";

http.setJwt(getJwt());

export function getJwt() {
	return localStorage.getItem(tokenKey);
}

export async function login(user) {
	const { data: jwt } = await http.post(apiEndpoint + "/login", user);
	try {
		await localStorage.setItem(tokenKey, jwt);
		return jwt;
	} catch (error) {}
}

export async function changepass(oldpass, newpass) {
	return await http.post("/userauth/changepass", {
		oldpass,
		newpass,
	});
}

export function logout() {
	localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
	try {
		const jwt = localStorage.getItem(tokenKey);
		const some = jwtDecode(jwt);
		return some;
	} catch (ex) {
		return null;
	}
}

export async function uploadPic(tok, imdata) {
	try {
		return await localStorage.setItem(tok, imdata);
	} catch (error) {
		return error;
	}
}

export async function getPic() {
	const val = await localStorage.getItem("Profile");
	if (val !== null) {
		return val;
	} else {
		return { err: "Nodata" };
	}
}

// export async function settxcode(tpin) {
// 	return await http.post("/userauth/instpin", { tpin });
// }
export async function getcategories() {
	return await http.post(apiEndpoint + "/getcat");
}
export async function getlist() {
	return await http.post(apiEndpoint + "/getlist");
}

// export async function valtxcode(tpin) {
// 	return await http.post("/userauth/valtpin", { tpin });
// }

export async function getaddresses() {
	return await http.post("/userauth/getaddresses");
}
export async function sendotp(sendtype) {
	return await http.post("/userauth/otpsupdate", { type: sendtype });
}

export async function validateotp(sendtype, otp) {
	return await http.post("/userauth/checkotp", { type: sendtype, otp });
}

// export async function trasansferhis() {
// 	return await http.post("/userauth/depwithhis");
// }
// export async function combindhistory() {
// 	return await http.post("/userauth/allhis");
// }
// export async function buysellcrypto() {
// 	return await http.post("/userauth/transferhis");
// }
// export async function updateprofile(obj) {
// 	return await http.post("/userauth/updateprof", obj);
// }
// export async function getprofile() {
// 	return await http.post("/userauth/getprofile");
// }
// export async function getpendcrypt(coin) {
// 	return await http.post("/userauth/getpendcrypt", { coin });
// }
// export async function getapikeys() {
// 	return await http.post("/userauth/getkeys");
// }
// export async function deleteapikeys(id) {
// 	return await http.post("/userauth/deletekeys", { id: id });
// }
// export async function merchanthistory() {
// 	return await http.post("/merchtrans/merchanthis");
// }
// export async function generatekeys(transferstatus) {
// 	return await http.post("/userauth/generatekeys", {
// 		transfer: transferstatus,
// 	});
// }
// export async function getnewcode(phone, type) {
// 	return await http.post(apiEndpoint + "/resendsms", { phone, type });
// }
export default {
	login,
	logout,
	getCurrentUser,
	changepass,
	getcategories,
	getPic,
	uploadPic,
	//settxcode
	getaddresses,
	sendotp,
	validateotp,
	getlist,

	//valtxcode,
	//buysellcrypto,
	// trasansferhis,
	// updateprofile,
	// getprofile,
	// getpendcrypt,
	// getapikeys,
	// deleteapikeys,
	// generatekeys,
	// merchanthistory,
	// getnewcode,
	// combindhistory,
};
