import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Topproducts extends Component {
  state = {
    product: [],
    loader: false,
  };

  productpage = (item) => {
    this.props.history.push({
      pathname: "/productdetails",
      state: { data: item },
    });
  };

  render() {
    const product = this.props.allproducts;

    return (
      <React.Fragment>
        <div className="shopify-section">
          <section className="section">
            <div className="container">
              <header className="section_header">
                <div className="section_header-stack">
                  <h2 className="section_title heading h3">
                    Top-rated products
                  </h2>
                </div>
              </header>

              <div className="col-md-12">
                <div className="row">
                  {product ? (
                    product.splice(0, 2).map((item, index) => {
                      return (
                        <div
                          //   onClick={() => this.productpage(item)}
                          key={index}
                          className="product-list product-list--collage col-lg-6 "
                        >
                          <div className="product-list_column product-list_column--highlight">
                            <div
                              // onClick={() => this.productpage(item)}
                              className="product-item product-item--vertical  "
                            >
                              <div className="product-item_label-list">
                                <span className="product-label product-label--on-sale">
                                  Save{" "}
                                  <span data-money-convertible>
                                    {" "}
                                    ₹{item.mrp - item.dealprice}
                                  </span>
                                </span>
                              </div>
                              {/* <Link  className="product-item_image-wrapper "> */}
                              <img
                                onClick={() => this.productpage(item)}
                                className="product-item_primary-image lazyload "
                                src={item.image[0]}
                                alt="img"
                                style={{
                                  cursor: "pointer",
                                  height: "250px",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                              />
                              {/* </Link> */}

                              <div className="product-item_info">
                                <div className="product-item_info-inner">
                                  {item.category}
                                  <Link
                                    // onClick={() => this.productpage()}
                                    className="product-item_title text--strong link"
                                  >
                                    {item.title}
                                  </Link>

                                  <div className="product-item_price-list price-list">
                                    <span className="price price--highlight">
                                      <span> ₹{item.dealprice}</span>
                                    </span>
                                    <span className="price price--compare">
                                      ₹{item.mrp}
                                    </span>
                                  </div>
                                  <span className="product-item_inventory inventory inventory--high">
                                    In stock,{item.stock} units
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="product-list_column">
                            {product ? (
                              product.splice(2, 2).map((item, index) => {
                                return (
                                  <div
                                    onClick={() => this.productpage(item)}
                                    key={index}
                                    className="product-item product-item--horizontal  "
                                  >
                                    <div
                                      key={index}
                                      className="product-item_image-wrapper "
                                    >
                                      <div className="aspect-ratio aspect-ratio--square">
                                        <Link
                                          // onClick={() => this.productpage(item)}
                                          className="product-item_title text--strong link"
                                        >
                                          <img
                                            className="product-item_primary-image lazyload "
                                            src={item.image}
                                            alt="img"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="product-item_info">
                                      <div className="product-item_info-inner">
                                        {" "}
                                        {item.title.slice(0, 90)}
                                        <div className="product-item_price-list price-list">
                                          <span className="price">
                                            ₹{item.dealprice}
                                          </span>
                                        </div>
                                        <span className="product-item_inventory inventory inventory--high">
                                          {" "}
                                          In stock, {item.stock} units
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="offset-5">
                                <img
                                  src="https://wpamelia.com/wp-content/uploads/2018/11/ezgif-2-6d0b072c3d3f.gif"
                                  alt="loader"
                                />
                              </div>
                            )}
                            {/* {item.image.length > 0
                              ? item.image.slice(1, 3).map((img, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="product-item product-item--horizontal  "
                                    >
                                      <div
                                        key={index}
                                        className="product-item_image-wrapper "
                                      >
                                        <div className="aspect-ratio aspect-ratio--square">
                                          <img
                                            className="product-item_primary-image lazyload "
                                            src={img}
                                            alt="img"
                                          />
                                        </div>
                                      </div>
                                      <div className="product-item_info">
                                        <div className="product-item_info-inner">
                                          <Link>{item.title.slice(0, 90)}</Link>
                                          <div className="product-item_price-list price-list">
                                            <span className="price">
                                              ₹{item.dealprice}
                                            </span>
                                          </div>
                                          <span className="product-item_inventory inventory inventory--high">
                                            {" "}
                                            In stock, {item.qty} units
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : null} */}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="offset-sm-4">
                      <img
                        src={require("../../assets/images/loader.gif")}
                        // src="https://media.tenor.com/images/7d9cb36e95124fb829ff8f2450c3a567/tenor.gif"
                        alt="loader"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="container container--flush">
							{product.length > 0
								? product.map((item, index) => {
										return (
											<div className="product-list product-list--collage">
												<div className="product-list_column product-list_column--highlight">
													<div
														key={index}
														onClick={() => this.productpage(item)}
														className="product-item product-item--vertical "
													>
														<div className="product-item_label-list">
															<span className="product-label product-label--on-sale">
																Save &nbsp;
																<span data-money-convertible>
																	₹{item.mrp - item.deal}
																</span>
															</span>
														</div>

														<a className="product-item_image-wrapper ">
															<img
																className="product-item_primary-image lazyload "
																src={item.image[0]}
																alt="img"
															/>
														</a>

														<div className="product-item_info">
															<div className="product-item_info-inner">
																<a className="product-item_vendor link">Sony</a>
																<a className="product-item_title text--strong link">
																	{item.title}
																</a>

																<div className="product-item_price-list price-list">
																	<span className="price price--highlight">
																		From <span>₹{item.deal}</span>
																	</span>
																	<span className="price price--compare">
																		₹{item.mrp}
																	</span>
																</div>
																{item.qty ? (
																	<span className="product-item_inventory inventory inventory--high">
																		In stock, {item.qty} units
																	</span>
																) : (
																	<span className="product-item_inventory inventory outinventory--high">
																		Out Of stock
																	</span>
																)}
															</div>
														</div>
													</div>
												</div>

												<div className="product-list_column ">
													<div className="product-item product-item--horizontal ">
														<a href="/" className="product-item_image-wrapper ">
															<div className="aspect-ratio aspect-ratio--square">
																<img
																	className="product-item_primary-image lazyload image--blur-up"
																	src={require("../../assets/images/products/toprate.jpg")}
																	alt="img"
																/>
															</div>
														</a>
														<div className="product-item_info">
															<div className="product-item_info-inner">
																<a className="" href="/">
																	Yamaha
																</a>
																<a
																	href="/"
																	className="product-item_title text--strong link"
																>
																	Yamaha WX-030
																</a>
																<div className="product-item_price-list price-list">
																	<span className="price">₹249.95</span>
																</div>
																<span className="product-item_inventory inventory inventory--high">
																	In stock, 160 units
																</span>
															</div>
														</div>
													</div>

													<div className="product-item product-item--horizontal ">
														<a href="/" className="product-item_image-wrapper ">
															<div className="aspect-ratio aspect-ratio--square">
																<img
																	className="product-item_primary-image lazyload "
																	src={require("../../assets/images/products/toprate.jpg")}
																	alt="img"
																/>
															</div>
														</a>
														<div className="product-item_info">
															<div className="product-item_info-inner">
																<a className="" href="/">
																	Yamaha
																</a>
																<a
																	href="/"
																	className="product-item_title text--strong link"
																>
																	Yamaha WX-030
																</a>
																<div className="product-item_price-list price-list">
																	<span className="price">₹249.95</span>
																</div>
																<span className="product-item_inventory inventory inventory--high">
																	In stock, 160 units
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										);
								  })
								: null}
						</div> */}
          </section>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allproducts: state.getproducts,
    getcategorys: state.getcategorys,
  };
};

export default connect(mapStateToProps)(Topproducts);
// export default Topproducts;
