import React, { Component } from "react";
import Collapsible from "react-collapsible";
import Header from "../common/header";
import cart from "../../services/cartService";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CreditCardInput from "react-credit-card-input";
import { toast } from "react-toastify";
import get_address from "../../redux/actions/getaddressActions";

var Joi = require("joi-browser");

const schema = Joi.object().keys({
	fullname: Joi.string().required(),
	doornumber: Joi.string().required(),
	street: Joi.string().required(),
	area: Joi.string().required(),
	landmark: Joi.string().required(),
	city: Joi.string().required(),
	state: Joi.string().required(),
	pincode: Joi.string().required(),
	mobile: Joi.string().min(4).max(10).required(),
});
class Checkout extends Component {
	state = {
		fullname: "",
		doornumber: "",
		street: "",
		area: "",
		landmark: "",
		city: "",
		state: "",
		pincode: "",
		mobile: "",
		updata: {},
		update: false,
		upi: "",
		Indstates: [
			{ id: 0, value: "State" },
			{ id: 1, value: "Jammu and Kashmir" },
			{ id: 2, value: "Punjab" },
			{ id: 3, value: "Himachal Pradesh" },
			{ id: 4, value: "Haryana" },
			{ id: 5, value: "Delhi" },
			{ id: 6, value: "Rajasthan" },
			{ id: 7, value: " Uttar Pradesh" },
			{ id: 8, value: "Uttarakhand" },
			{ id: 9, value: " Madhya Pradesh" },
			{ id: 10, value: "Chattisgarh" },
			{ id: 11, value: "Gujarat" },
			{ id: 12, value: "Maharashtra" },
			{ id: 13, value: "Karnataka" },
			{ id: 14, value: "Goa" },
			{ id: 15, value: "Kerala" },
			{ id: 16, value: "Tamil nadu" },
			{ id: 17, value: "Andhra pradesh" },
			{ id: 18, value: "Telangana" },
			{ id: 19, value: "Orissa" },
			{ id: 20, value: "Bihar" },
			{ id: 21, value: "Jharkhand" },
			{ id: 22, value: "West Bengal" },
			{ id: 23, value: "Assam" },
			{ id: 24, value: "Arunach Pradesh" },
			{ id: 25, value: "Sikkim" },
			{ id: 26, value: "Meghalaya" },
			{ id: 27, value: "Mizoram" },
			{ id: 28, value: "Nagaland" },
			{ id: 29, value: "Tripura" },
		],
		value: "",
		disabled: false,
		paymentmethod: "",
	};
	componentDidMount = async () => {
		get_address();
	};

	handleChange = async (evt) => {
		await this.setState({
			[evt.target.name]: evt.target.value,
			value: evt.target.value,
		});
	};
	radiohandleChange = async (value) => {
		await this.setState({ paymentmethod: value });
		console.log(this.state.paymentmethod);
	};
	addaddress = async () => {
		this.setState({ disabled: !this.state.disabled });
		toast.configure();
		const {
			fullname,
			doornumber,
			street,
			area,
			landmark,
			city,
			state,
			pincode,
			mobile,
		} = this.state;

		let val = "";
		const validata = Joi.validate(
			{
				fullname,
				doornumber,
				street,
				area,
				landmark,
				city,
				state,
				pincode,
				mobile,
			},
			schema,
			function (err, value) {
				if (!err) return null;
				const reterr = err.details[0].message;
				val = err.details[0].context.key;
				return reterr;
			},
		);
		if (!!validata) {
			toast.error(validata, {
				position: toast.POSITION.BOTTOM_LEFT,
			});
			setTimeout(async () => {
				if (val === "fullname") {
					this.setState({ fullname: "" });
				} else if (val === "doornumber") {
					this.setState({ doornumber: "" });
				} else if (val === "street") {
					this.setState({ street: "" });
				} else if (val === "area") {
					this.setState({ area: "" });
				} else if (val === "landmark") {
					this.setState({ landmark: "" });
				} else if (val === "city") {
					this.setState({ city: "" });
				} else if (val === "state") {
					this.setState({ state: "" });
				} else if (val === "pincode") {
					this.setState({ pincode: "" });
				} else if (val === "mobile") {
					this.setState({ mobile: "" });
				}
			}, 2000);
		} else {
			try {
				const obj = {
					fullname: this.state.fullname,
					drnum: this.state.doornumber,
					street: this.state.street,
					area: this.state.area,
					city: this.state.city,
					state: this.state.state,
					pincode: this.state.pincode,
					other: this.state.landmark,
					contact: this.state.mobile,
				};

				const data = await cart.addaddress(obj);
				if (data.data.success) {
					toast.success(data.data.success, {
						position: toast.POSITION.BOTTOM_LEFT,
					});
					get_address();
					setTimeout(async () => {
						await this.setState({
							fullname: "",
							doornumber: "",
							street: "",
							area: "",
							landmark: "",
							city: "",
							state: "",
							pincode: "",
							mobile: "",
						});
					}, 2000);
				}
			} catch (ex) {
				if (ex.response && ex.response.status === 400) {
					toast.error(ex.response.data, {
						position: toast.POSITION.BOTTOM_LEFT,
					});
					setTimeout(async () => {
						await this.setState({
							fullname: "",
							doornumber: "",
							street: "",
							area: "",
							landmark: "",
							city: "",
							state: "",
							pincode: "",
							mobile: "",
						});
					}, 2000);
				}
			}
		}
	};
	changeaddress = async (item, event) => {
		toast.configure();
		if (this.state.value === item.adrsid) {
			await this.setState({ update: true, updata: item });
		} else {
			toast.error("Select Address", {
				position: toast.POSITION.BOTTOM_LEFT,
			});
		}
	};
	updateaddress = async () => {
		toast.configure();
		const {
			fullname,
			doornumber,
			street,
			area,
			landmark,
			city,
			state,
			pincode,
			mobile,
			updata,
		} = this.state;
		const upobj = {
			adrsid: updata.adrsid,
			fullname: fullname ? fullname : updata.fullname,
			drnum: doornumber ? doornumber : updata.drnum,
			street: street ? street : updata.street,
			area: area ? area : updata.area,
			city: city ? city : updata.city,
			state: state ? state : updata.state,
			pincode: pincode ? pincode : updata.pincode,
			other: landmark ? landmark : updata.other,
			contact: mobile ? mobile : updata.mobile,
		};

		try {
			const data = await cart.updateaddress(upobj);
			if (data) {
				await this.setState({ update: false });
				toast.success("Updated Successfully", {
					position: toast.POSITION.BOTTOM_LEFT,
				});
				get_address();
				await this.setState({
					fullname: "",
					doornumber: "",
					street: "",
					area: "",
					landmark: "",
					city: "",
					state: "",
					pincode: "",
					mobile: "",
				});
			}
		} catch (ex) {
			if (ex.response && ex.response.status === 400) {
				toast.error(ex.response.data, {
					position: toast.POSITION.BOTTOM_LEFT,
				});
				setTimeout(async () => {
					await this.setState({
						fullname: "",
						doornumber: "",
						street: "",
						area: "",
						landmark: "",
						city: "",
						state: "",
						pincode: "",
						mobile: "",
					});
				}, 2000);
			}
		}
	};
	productpage = (item) => {
		this.props.history.push({
			pathname: "/productdetails",
			state: { data: item.item[0] },
		});
	};
	deleteaddress = async (item) => {
		toast.configure();
		item.map(async (it) => {
			if (this.state.value === it.adrsid) {
				const obj = {
					adrsid: it.adrsid,
				};
				console.log(obj);
				const data = await cart.deleteaddress(obj);
				if (data.data.success) {
					toast.success(data.data.success, {
						position: toast.POSITION.BOTTOM_LEFT,
					});
					await get_address();
				}
			} else {
				toast.error("Select Address", {
					position: toast.POSITION.BOTTOM_LEFT,
				});
			}
		});
	};
	checkoutPage = (item) => {
		this.props.history.push({
			pathname: "/checkout",
			state: { data: item },
		});
	};
	render() {
		var ss;
		if (this.props.cartlist && this.props.cartlist.length) {
			var tcount = [];
			const tot = this.props.cartlist.map((element) => {
				const t = element.item.map((it, index) => {
					const tt = parseInt(it.dealprice) * parseInt(element.qty);
					tcount.push(tt);
					var sum = tcount.reduce(function (acc, num) {
						acc += num;
						return acc;
					}, 0);

					ss = sum.toFixed(2);
					return tt;
				});
			});
		}

		const states = this.state.Indstates;
		const getaddress = this.props.getaddress;

		return (
			<React.Fragment>
				<Header history={this.props.history} />
				<div className="container">
					<div className="page_sub-header">
						<header className="page_header page__header--stack">
							<h1 className="page_title heading h1"> Checkout </h1>
						</header>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="card">
								<div className="card__header d-flex pr-2 pl-2 pt-3">
									<div className="col">
										<h2 className="card__title heading h4">
											Shipping Address{" "}
										</h2>
									</div>

									<div class="col text-right">
										<button
											// onClick={() => this.changeaddress(this.props.getaddress)}
											type="submit"
											className="button button--primary btn-sm  mb-1"
											data-toggle="collapse"
											href="#collapseExample"
											role="button"
											aria-expanded="false"
											aria-controls="collapseExample"
										>
											Add
										</button>
										<button
											onClick={() => {
												// if (
												//   window.confirm("Delete the address?")
												// )
												this.deleteaddress(this.props.getaddress);
											}}
											value={this.props.getaddress}
											type="submit"
											className="button btn-sm button--secondary"
										>
											Delete
										</button>
									</div>
								</div>
								<hr class="card__separator mt-2 mb-3" />

								<div className="product-list product-list--collection card__section pt-2">
									<div className="row">
										{getaddress && getaddress.length > 0 ? (
											getaddress.map((ad, index) => {
												return (
													<div>
														<div class="d-flex 1/2--desk">
															<input
																onSelect={this.handleChange}
																type="radio"
																className="radio ml-3"
																id="filter-3-tag-brand_akg"
																name="tag-brand"
																value={ad.adrsid}
																checked={this.props.getaddress.adrsid}
																// checked={this.props.getaddress.adrsid}
																onChange={this.handleChange}
																// onChange={() => this.togglecatcollapse()}
															/>
															<h3 className="mr-3 ml-3 text-bold text-nowrap">
																{" "}
																{ad.fullname}{" "}
															</h3>
														</div>
														<p className="ml-5">
															{ad.drnum}, {ad.street},{ad.area}, <br />
															{ad.city} ,{ad.state},{ad.pincode} <br />
															{ad.contact}
														</p>
													</div>
													// </div>
												);
											})
										) : (
											<h4 className="text-center mt-4">No Address Found</h4>
										)}
									</div>
								</div>
							</div>
							<div class="collapse" id="collapseExample">
								<div className="card">
									{!this.state.update ? (
										<div className="card__section ">
											<div className="form__header">
												<h2 className="heading h3">
													Add new Shipping address{" "}
												</h2>{" "}
											</div>
											<div className="form__input-row">
												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-name"
														type="text"
														className="form__field form__field--text "
														name="fullname"
														value={this.state.fullname}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-name"
														className="form__floating-label"
													>
														Full Name
													</label>
												</div>
												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-name"
														type="text"
														className="form__field form__field--text "
														name="doornumber"
														value={this.state.doornumber}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-name"
														className="form__floating-label"
													>
														DOOR.No
													</label>
												</div>
												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-email"
														type="email"
														className="form__field form__field--text "
														name="street"
														value={this.state.street}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-email"
														className="form__floating-label"
													>
														Street
													</label>
												</div>
											</div>
											<div className="form__input-row">
												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-email"
														type="email"
														className="form__field form__field--text "
														name="area"
														value={this.state.area}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-email"
														className="form__floating-label"
													>
														Area
													</label>
												</div>
												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-name"
														type="text"
														className="form__field form__field--text "
														name="landmark"
														value={this.state.landmark}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-name"
														className="form__floating-label"
													>
														Land Mark
													</label>
												</div>

												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-email"
														className="form__field form__field--text "
														name="city"
														value={this.state.city}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-email"
														className="form__floating-label"
													>
														City
													</label>
												</div>
											</div>
											<div className="form__input-row">
												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-name"
														type="number"
														className="form__field form__field--text "
														name="mobile"
														value={this.state.mobile}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-name"
														className="form__floating-label"
													>
														Mobile No
													</label>
												</div>

												<div className="form__input-wrapper form__input-wrapper--labelled">
													<div className="select-wrapper select-wrapper--primary">
														<svg
															className="icon icon--arrow-bottom"
															viewBox="0 0 12 8"
															role="presentation"
														>
															<path
																stroke="currentColor"
																stroke-width="2"
																d="M10 2L6 6 2 2"
																fill="none"
																stroke-linecap="square"
															/>
														</svg>
														<select
															value={this.state.state}
															onChange={this.handleChange}
															name="state"
														>
															{states
																? states.map((st, id) => {
																		return (
																			<optgroup key={id}>
																				<option value={st.value}>
																					{" "}
																					{st.value}{" "}
																				</option>
																			</optgroup>
																		);
																  })
																: null}
														</select>
													</div>
												</div>
												<div className="form__input-wrapper form__input-wrapper--labelled">
													<input
														id="contact-form-name"
														type="number"
														className="form__field form__field--text "
														name="pincode"
														value={this.state.pincode}
														onChange={this.handleChange}
													/>
													<label
														for="contact-form-name"
														className="form__floating-label"
													>
														Pin Code
													</label>
												</div>
											</div>
											<div
												disabled={this.state.disabled}
												onClick={this.addaddress}
												className="form__submit  button button--primary button--min-width"
											>
												{" "}
												Save & Continue to shipping
											</div>
										</div>
									) : null}
								</div>
							</div>

							<div className="card">
								<div className="card__header pl-4 pt-3">
									<h2 className="card__title heading h4">Payment Method</h2>
								</div>
								<hr class="card__separator mt-2 mb-3" />
								<div className="product-list product-list--collection card__section pt-2">
									{" "}
									<div className="row">
										<ul>
											<li className="d-flex mb-3">
												<input
													// onSelect={this.handleChange}
													type="radio"
													className="radio ml-3"
													id="filter-3-tag-brand_akg"
													name="tag-brand"
													// checked={this.props.getaddress.adrsid}
													onChange={() => this.radiohandleChange("UPI")}
												/>

												<h3 className="mr-3 ml-3 text-bold text-nowrap">
													{" "}
													UPI (Phonepay/Google Pay)
												</h3>
											</li>
											{this.state.paymentmethod === "UPI" ? (
												<div class="mb-3 ml-3 d-flex" id="upi">
													<input
														id="upi"
														type="text"
														class="form__field form__field--text "
														name="upi"
														value={this.state.upi}
														onChange={this.handleChange}
													/>
													<a
														type="submit"
														class="button button--primary pt-1"
														href=""
													>
														{" "}
														Verify{" "}
													</a>
												</div>
											) : null}

											<li className="d-flex mb-3">
												<input
													// onSelect={this.handleChange}
													type="radio"
													className="radio ml-3"
													id="filter-3-tag-brand_akg"
													name="tag-brand"
													// checked={this.props.getaddress.adrsid}
													onChange={() => this.radiohandleChange("card")}
												/>
												<h3 className="mr-3 ml-3 text-bold text-nowrap">
													Credit / Debit Card{" "}
												</h3>
											</li>
											{this.state.paymentmethod === "card" ? (
												<div
													class="mb-3 ml-3 "
													id="card"
													style={{
														border: "2px solid #d4d6d8",
														margin: "inherit",
														padding: 5,
													}}
												>
													<CreditCardInput
														cardNumberInputProps={{
															// value: cardNumber,
															onChange: this.handleCardNumberChange,
														}}
														cardExpiryInputProps={{
															// value: expiry,
															onChange: this.handleCardExpiryChange,
														}}
														cardCVCInputProps={{
															// value: cvc,
															onChange: this.handleCardCVCChange,
														}}
														fieldClassName="input"
													/>
												</div>
											) : null}

											<li className="d-flex mb-3">
												<input
													role="button"
													type="radio"
													className="radio ml-3"
													id="filter-3-tag-brand_akg"
													name="tag-brand"
													// checked={this.props.getaddress.adrsid}
													onChange={() => this.radiohandleChange("EMI")}
												/>
												<h3 className="mr-3 ml-3 text-bold text-nowrap">
													EMI (Easy installments)
												</h3>
											</li>
											{this.state.paymentmethod === "EMI" ? (
												<div
													class="mb-3 ml-3 "
													id="emi"
													style={{
														border: "2px solid #d4d6d8",
														margin: "inherit",
														padding: 5,
													}}
												>
													<p>EMI</p>
												</div>
											) : null}

											<li className="d-flex mb-3">
												<input
													onSelect={this.handleChange}
													type="radio"
													className="radio ml-3"
													id="filter-3-tag-brand_akg"
													name="tag-brand"
													// checked={this.props.getaddress.adrsid}
													onChange={() => this.radiohandleChange("Net Banking")}
												/>
												<h3 className="mr-3 ml-3 text-bold text-nowrap">
													Net Banking
												</h3>
											</li>
											<li className="d-flex mb-3">
												<input
													type="radio"
													className="radio ml-3"
													id="filter-3-tag-brand_akg"
													name="tag-brand"
													// checked={this.props.getaddress.adrsid}
													onChange={() => this.radiohandleChange("COD")}
												/>
												<h3 className="mr-3 ml-3 text-bold text-nowrap">
													COD (Cash on delivery)
												</h3>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-6">
							<div className="card">
								<div className="card__header pl-4 pt-3">
									<h2 className="card__title heading h4">
										{" "}
										Your Order Summary{" "}
									</h2>
								</div>
								<hr class="card__separator mt-2 mb-3" />
								<table className="line-item-table table table--checkout mb-1 table-responsive">
									<thead className="hidden-phone">
										<tr>
											<th>Product</th>
											<th className="table_cell--center">Quantity</th>
											<th className="table_cell--right mr-5">Total</th>
										</tr>
									</thead>

									<tbody>
										{this.props.cartlist && this.props.cartlist.length > 0
											? this.props.cartlist.map((item, index) => {
													return item && item.item.length > 0
														? item.item.map((it, index) => {
																return (
																	<tr className="line-item line-item--stack">
																		<td className="line-item_product-info">
																			<div className="line-item__product-info-wrapper">
																				<div className="line-item_image-wrapper">
																					<img src={it.image} alt="img" />
																				</div>

																				<div
																					style={{ cursor: "pointer" }}
																					className="line-item_meta"
																					onClick={() => this.productpage(item)}
																				>
																					{it.title}

																					{/* <div className="line-item_price-list">
                                                <span className="line-item_price">
                                                  ₹{it.dealprice}
                                                </span>
                                              </div> */}
																				</div>
																			</div>
																		</td>
																		<td className="line-item_quantity table_cell--center pt-2">
																			<div className="quantity-selector">
																				{/* <button
                                          onClick={() =>
                                            this.decrementItem(item)
                                          }
                                          type="button"
                                          className="quantity-selector__button"
                                          data-action="decrease-quantity"
                                          data-href="/cart/change?quantity=0&amp;id=17549718388787:ff8fd312f1435be00382509901c3a50d"
                                          data-quantity="0"
                                          data-line-id="17549718388787:ff8fd312f1435be00382509901c3a50d"
                                          aria-label="Decrease quantity by 1"
                                          title="Decrease quantity by 1"
                                        >
                                          <svg
                                            className="icon icon--minus"
                                            viewBox="0 0 10 2"
                                            role="presentation"
                                          >
                                            <path
                                              d="M10 0v2H0V0z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                        </button> */}
																				<input
																					aria-label="Quantity"
																					className="quantity-selector__value"
																					pattern="[0-9]*"
																					data-current-value="1"
																					data-line-id="17549718388787"
																					value={item.qty}
																					size="2"
																				/>
																				{/* <button
                                          onClick={() =>
                                            this.incrementItem(item)
                                          }
                                          type="button"
                                          className="quantity-selector__button"
                                          data-action="increase-quantity"
                                          data-href="/cart/change?quantity=2&amp;id=17549718388787:ff8fd312f1435be00382509901c3a50d"
                                          data-quantity="2"
                                          data-line-id="17549718388787:ff8fd312f1435be00382509901c3a50d"
                                          aria-label="Increase quantity by 1"
                                          title="Increase quantity by 1"
                                        >
                                          <svg
                                            className="icon icon--plus"
                                            viewBox="0 0 10 10"
                                            role="presentation"
                                          >
                                            <path
                                              d="M6 4h4v2H6v4H4V6H0V4h4V0h2v4z"
                                              fill="currentColor"
                                              fillRule="evenodd"
                                            ></path>
                                          </svg>
                                        </button> */}
																			</div>
																			{/* <Link
                                        onClick={() => this.deletecartid(item)}
                                        data-action="decrease-quantity"
                                        data-quantity="0"
                                        data-line-id="17549718388787:ff8fd312f1435be00382509901c3a50d"
                                        className="mini-cart__quantity-remove link pt-3 m-auto"
                                      >
                                        Remove
                                      </Link> */}
																		</td>
																		<td className="line-item_line-price table_cell--right text-bold pt-2 pr-0">
																			<span>
																				₹{(item.qty * it.dealprice).toFixed(2)}
																			</span>
																		</td>
																	</tr>
																);
														  })
														: null;
											  })
											: null}
									</tbody>
								</table>
								<div className="card-body pb-0 pt-0">
									<hr />
									<div className="cart-recap_price-line text--pull ">
										<span className="cart-recap_price-line-label">
											{" "}
											Shipping & Handling{" "}
										</span>
										<span className="cart-recap_price-line-price">₹10.95</span>
									</div>
									<hr className="card__separator mt-2 mb-3" />
									<div className="cart-recap_price-line text--pull">
										<span className="cart-recap_price-line-label">
											{" "}
											Estimated Tax{" "}
										</span>
										<span className="cart-recap_price-line-price">₹1.95</span>
									</div>
									<hr className="card__separator mt-2 mb-3" />
									<div className="cart-recap_price-line text--pull">
										<span className="h4 text-bold theme-text">
											{" "}
											Total Amount{" "}
										</span>
										<span className="h4 text-bold theme-text">₹{ss}</span>
									</div>
								</div>
							</div>
							<Link
								// onClick={this.checkoutpage}
								// to={{ pathname: "/checkout", data: cart }}
								type="submit"
								className="button button--primary float-right mt-0"
							>
								CheckOut
							</Link>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		getaddress: state.getaddress,
		cartlist: state.cartlist,
	};
};
export default connect(mapStateToProps)(Checkout);
