import React, { Component } from "react";
import { toast } from "react-toastify";
import Header from "../common/header";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import cart_list from "../../redux/actions/cartlistActions";
import cart from "../../services/cartService";
import { connect } from "react-redux";

import OwlCarousel from "react-owl-carousel-safe";

class Viewcart extends Component {
  state = {
    cproduct: [],
    ctot: [],
    cid: "",
    cart: [],
    cproductid: "",
    qty: "",
  };
  componentDidMount = async () => {
    if (!this.props.cartlist) {
      this.props.history.push("/home");
    }
    await cart_list();
    // this.tprice();
  };
  checkoutpage = () => {
    this.props.history.push({
      pathname: "/checkout",
      state: { data: this.state.cartitems },
    });
  };
  // Notify = () => {
  //   toast("This seller has a limit of 10 per customer");
  // };
  // Notifyremove = () => {
  //   toast("Do you want to delete the item..");
  // };
  // tprice = async () => {
  //   if (this.props.cartlist && this.props.cartlist.length) {
  //     var tcount = [];
  //     const tot = this.props.cartlist.map((element) => {
  //       const t = element.item.map((it, index) => {
  //         const tt = parseInt(it.dealprice) * parseInt(element.qty);
  //         tcount.push(tt);
  //         var sum = tcount.reduce(function (acc, num) {
  //           console.log(acc, num);
  //           acc += num;
  //           return acc;
  //         }, 0);
  //         this.setState({ ctot: sum.toFixed(2) });

  //         return tt;
  //       });
  //     });
  //     await cart_list();
  //   }
  // };

  async incrementItem(item) {
    const products = this.props.cartlist;
    const i = products.filter((i) => i.itemid === item.itemid);
    this.setState({ cproduct: i });
    if (i[0].qty < 10) {
      i[0].qty += 1;
      await this.setState({ cproductid: i[0].cartid, qty: i[0].qty });
    } else {
      this.Notify();
    }
    const obj = {
      cartid: this.state.cproductid,
      qty: this.state.qty,
    };
    const cartnup = await cart.updatecart(obj);
    await cart_list();
    // this.tprice();
  }
  async decrementItem(item) {
    const products = this.props.cartlist;
    const i = products.filter((i) => i.itemid === item.itemid);
    this.setState({ cproduct: i });
    if (i[0].qty > 1) {
      i[0].qty -= 1;
      await this.setState({ cproductid: i[0].cartid, qty: i[0].qty });
    } else {
      this.Notifyremove();
    }
    const obj = {
      cartid: this.state.cproductid,
      qty: this.state.qty,
    };
    const cartnup = await cart.updatecart(obj);
    await cart_list();
    // this.tprice();
  }

  deletecartid = async (item) => {
    toast.configure();
    this.setState({ cid: item.cartid });
    const obj = {
      cartid: item.cartid,
    };
    const data = await cart.deletecart(obj);
    if (data.data.success) {
      toast.success(data.data.success, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      await cart_list();
      // this.tprice();
      // await this.setState({ cart: da });
    }
  };
  productpage = (item) => {
    this.props.history.push({
      pathname: "/productdetails",
      state: { data: item.item[0] },
    });
  };
  productpage1 = (item) => {
    this.props.history.push({
      pathname: "/productdetails",
      state: { data: item },
    });
  };
  checkoutpage = () => {
    this.props.history.push({ pathname: "/checkout" });
  };

  render() {
    var ss;
    if (this.props.cartlist && this.props.cartlist.length) {
      var tcount = [];
      const tot = this.props.cartlist.map((element) => {
        const t = element.item.map((it, index) => {
          const tt = parseInt(it.dealprice) * parseInt(element.qty);
          tcount.push(tt);
          var sum = tcount.reduce(function (acc, num) {
            acc += num;
            return acc;
          }, 0);

          ss = sum.toFixed(2);
          return tt;
        });
      });
    }

    const cart = this.props.cartlist;
    const gp = this.props.getproducts;

    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="container">
          <div className="page_sub-header">
            <header className=" page__header--stack">
              <h1 className="page_title heading h1">My cart</h1>
              <p className="page_description">
                You are eligible for free shipping!
              </p>
            </header>
          </div>
        </div>

        <div className="cart-wrapper">
          <div className="cart-wrapper_inner">
            <div className="cart-wrapper_inner-inner">
              <div className="container">
                <div className="card">
                  <div className="table-wrapper">
                    <table className="line-item-table table table--loose mb-1">
                      <thead className="hidden-phone">
                        <tr>
                          <th>Product</th>
                          <th className="table_cell--center">Quantity</th>
                          <th className="table_cell--right mr-5">Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.cartlist && this.props.cartlist.length > 0
                          ? this.props.cartlist.map((item, index) => {
                              return item && item.item.length > 0
                                ? item.item.map((it, index) => {
                                    return (
                                      <tr className="line-item line-item--stack">
                                        <td className="line-item_product-info">
                                          <div className="line-item__product-info-wrapper">
                                            <div className="line-item_image-wrapper">
                                              <img src={it.image} alt="img" />
                                            </div>

                                            <div
                                              style={{ cursor: "pointer" }}
                                              className="line-item_meta"
                                              onClick={() =>
                                                this.productpage(item)
                                              }
                                            >
                                              {it.title}

                                              {/* <div className="line-item_price-list">
                                                <span className="line-item_price">
                                                  ₹{it.dealprice}
                                                </span>
                                              </div> */}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="line-item_quantity table_cell--center pt-4">
                                          <div className="quantity-selector">
                                            <button
                                              onClick={() =>
                                                this.decrementItem(item)
                                              }
                                              type="button"
                                              className="quantity-selector__button"
                                              data-action="decrease-quantity"
                                              data-href="/cart/change?quantity=0&amp;id=17549718388787:ff8fd312f1435be00382509901c3a50d"
                                              data-quantity="0"
                                              data-line-id="17549718388787:ff8fd312f1435be00382509901c3a50d"
                                              aria-label="Decrease quantity by 1"
                                              title="Decrease quantity by 1"
                                            >
                                              <svg
                                                className="icon icon--minus"
                                                viewBox="0 0 10 2"
                                                role="presentation"
                                              >
                                                <path
                                                  d="M10 0v2H0V0z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </button>
                                            <input
                                              aria-label="Quantity"
                                              className="quantity-selector__value"
                                              pattern="[0-9]*"
                                              data-current-value="1"
                                              data-line-id="17549718388787"
                                              value={item.qty}
                                              size="2"
                                            />
                                            <button
                                              onClick={() =>
                                                this.incrementItem(item)
                                              }
                                              type="button"
                                              className="quantity-selector__button"
                                              data-action="increase-quantity"
                                              data-href="/cart/change?quantity=2&amp;id=17549718388787:ff8fd312f1435be00382509901c3a50d"
                                              data-quantity="2"
                                              data-line-id="17549718388787:ff8fd312f1435be00382509901c3a50d"
                                              aria-label="Increase quantity by 1"
                                              title="Increase quantity by 1"
                                            >
                                              <svg
                                                className="icon icon--plus"
                                                viewBox="0 0 10 10"
                                                role="presentation"
                                              >
                                                <path
                                                  d="M6 4h4v2H6v4H4V6H0V4h4V0h2v4z"
                                                  fill="currentColor"
                                                  fillRule="evenodd"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                          <Link
                                            onClick={() =>
                                              this.deletecartid(item)
                                            }
                                            data-action="decrease-quantity"
                                            data-quantity="0"
                                            data-line-id="17549718388787:ff8fd312f1435be00382509901c3a50d"
                                            className="mini-cart__quantity-remove link pt-3 m-auto"
                                          >
                                            Remove
                                          </Link>
                                        </td>
                                        <td className="line-item_line-price table_cell--right text-bold pt-4">
                                          <span>
                                            ₹
                                            {(item.qty * it.dealprice).toFixed(
                                              2
                                            )}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null;
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="card">
                  <div className="gift-wrap">
                    <div className="gift-wrap__left">
                      <span className="gift-wrap__icon">
                        <svg viewBox="0 0 24 24" role="presentation">
                          <g strokeWidth="2" fill="none" fillRule="evenodd">
                            <path
                              d="M3 18l9-9M11.9999905 23.0000095l9.0000152-9.0000143M5 23L19 9"
                              stroke="#1e2d7d"
                            ></path>
                            <path
                              stroke="#1e2d7d"
                              strokeLinecap="square"
                              d="M21 9v14H3V9"
                            ></path>
                            <path
                              d="M6 3c0-1.1.9000001-2 2-2 3 0 4 4 4 4H8c-1.1000004 0-2-.9000001-2-2zM18 3c0-1.1-.8999996-2-2-2-3 0-4 4-4 4h4c1.1000004 0 2-.9000001 2-2z"
                              stroke="#00badb"
                            ></path>
                            <path stroke="#1e2d7d" d="M1 5h22v4H1z"></path>
                          </g>
                        </svg>
                      </span>
                      <p className="gift-wrap__text">
                        <span className="text--strong">
                          Do you want a gift wrap?
                        </span>
                        <span>
                          Only <span>₹2.50</span>
                        </span>
                      </p>
                    </div>
                    <button type="submit" className="button button--secondary">
                      Add a gift wrap
                    </button>
                  </div>
                </div>

                <div className="cart-recap">
                  <div className="cart-recap_scroller">
                    <div className="card">
                      <div className="card_section">
                        {cart && cart.length > 0
                          ? cart.map((item, index) => {
                              return item && item.item.length > 0
                                ? item.item.map((it, index) => {
                                    return (
                                      <div className="cart-recap_price-line text--pull">
                                        <span className="cart-recap_price-line-label">
                                          Order sum-
                                          {/* {item.itemid} */}
                                        </span>
                                        <span className="cart-recap_price-line-price">
                                          ₹
                                          {(item.qty * it.dealprice).toFixed(2)}
                                        </span>
                                      </div>
                                    );
                                  })
                                : null;
                            })
                          : null}
                        <div className="cart-recap_price-line text--pull border-top theme-text pt-3">
                          <span className="h4 text-bold ">Total</span>
                          <span className="h4 text-bold">₹{ss}</span>
                        </div>
                        <div className="cart-recap_note mt-0">
                          <Collapsible
                            trigger=" Order instructions "
                            className="my-3"
                          >
                            <div className="cart-recap__note-inner">
                              <textarea
                                className="form__field form__field--textarea"
                                rows="3"
                                autoFocus=""
                              ></textarea>
                              <button
                                type="button"
                                className="form_submit--tight button button--secondary"
                                data-action="save-note"
                              >
                                Save
                              </button>
                            </div>
                          </Collapsible>
                        </div>

                        <div className="cart-recap_notices rte">
                          <p>Tax included. Shipping calculated at checkout</p>
                        </div>

                        <Link
                          onClick={this.checkoutpage}
                          // to={{ pathname: "/checkout", data: cart }}
                          type="submit"
                          className="button button--primary button--full"
                        >
                          Place Order
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shopify-section">
          <section
            className="section"
            data-section-type="featured-collection"
            data-section-settings="{ }"
          >
            <div className="container">
              <header className="section_header">
                <div className="section_header-stack">
                  <h2 className="section_title heading h3">Recommended</h2>
                </div>
                <a
                  href="collections/sales.html"
                  className="section_action-link link"
                >
                  View all sales
                  <svg
                    className="icon icon--tail-right"
                    viewBox="0 0 24 24"
                    role="presentation"
                  >
                    <path
                      fill="currentColor"
                      d="M22.707 11.293L15 3.586 13.586 5l6 6H2c-.553 0-1 .448-1 1s.447 1 1 1h17.586l-6 6L15 20.414l7.707-7.707c.391-.391.391-1.023 0-1.414z"
                    ></path>
                  </svg>
                </a>
              </header>
            </div>

            <div className="container ">
              <div className="scroller">
                <div className="scroller_inner">
                  <OwlCarousel
                    autoPlay={true}
                    key={`carousel_${
                      this.props.getproducts ? this.props.getproducts : 0
                    }`}
                    nav={false}
                    responsiveClass={true}
                    items={5}
                    margin={0}
                    autoWidth={true}
                    loop={true}
                  >
                    {gp &&
                      gp.slice(0, 10).map((p, index) => {
                        return (
                          <div class="product-list product-list--vertical product-list--scrollable ">
                            <div
                              key={p._id}
                              class="product-item product-item--vertical"
                              style={{ width: "100%" }}
                            >
                              <div class="product-item_label-list">
                                <span class="product-label product-label--on-sale">
                                  Save
                                  <span>₹800</span>
                                </span>
                              </div>
                              <a href="" class="product-item_image-wrapper ">
                                <div class="aspect-ratio aspect-ratio--square">
                                  <img
                                    onClick={() => this.productpage1(p)}
                                    class="product-item_primary-image lazyload "
                                    src={p.image}
                                    // src={require("../../assets/images/products/pro1.jpg")}
                                  />
                                </div>
                              </a>

                              <div class="product-item_info">
                                <div class="product-item_info-inner">
                                  <a class="product-item_vendor link" href="">
                                    Sony
                                  </a>
                                  <a
                                    href=""
                                    class="product-item_title text--strong link"
                                  >
                                    Sony BRAVIA 4K HDR Ultra HD TV
                                  </a>
                                  <div class="product-item_price-list price-list">
                                    <span class="price price--highlight">
                                      ₹1,398
                                    </span>
                                    <span class="price price--compare">
                                      ₹2,198
                                    </span>
                                  </div>
                                  <span class="product-item_inventory inventory inventory--high">
                                    In stock, 80 units
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cartlist: state.cartlist,
    getproducts: state.getproducts,
  };
};

export default connect(mapStateToProps)(Viewcart);
